import React from "react";

function ClassStructure() {
    return (
        <div id="classstructure">
            <section className="bg-white">
                <div className="py-8 px-4 mx-auto max-w-screen-xl text-center sm:py-16 lg:px-6">
                    <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">How it Works</h2>
                    <p className="text-gray-500 sm:text-xl">Interested in a class? Here's how to get started</p>
                    <div className="mt-8 lg:mt-12 space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0 text-center justify-center">

                        <div>
                            <div className="flex justify-center items-center">
                                <span className="flex items-center text-2xl justify-center w-12 h-12 border-4 border-dodger bg-white text-dodger rounded-full shrink-0 ">
                                    1
                                </span>
                                <h3 className="mb-0 mx-4 text-2xl font-bold"> Purchase A Ticket</h3>
                            </div>
                            <p className="text-sm mt-4">Buy a class for your child, a nephew, grandchild - they make great gifts for kids with hidden tech talents!</p>
                            <div className="text-left">

                                <ul className="pt-8 my-5 space-y-5 border-t border-gray-300 ">
                                    <li className="flex space-x-3">

                                        <svg className="flex-shrink-0 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                        <span className="text-base font-medium leading-tight text-gray-500"> Classes last 5 days</span>
                                    </li>
                                    <li className="flex space-x-3">

                                        <svg className="flex-shrink-0 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                        <span className="text-base font-medium leading-tight text-gray-500">1 hr of live instruction each day</span>
                                    </li>
                                    <li className="flex space-x-3">

                                        <svg className="flex-shrink-0 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                        <span className="text-base font-medium leading-tight text-gray-500">Tickets redeemable for any course, all summer long</span>
                                    </li>
                                    <li className="flex space-x-3">

                                        <svg className="flex-shrink-0 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                        <span className="text-base font-medium leading-tight text-gray-500">Tickets valid and refundable for 18 months</span>
                                    </li>

                                    <li className="flex space-x-3">

                                        <svg className="flex-shrink-0 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                        <span className="text-base font-medium leading-tight text-gray-500">You only need a laptop & internet connection - no equipment or software!</span>
                                    </li>
                                </ul>

                                {/* <a href="/" className="inline-flex items-center text-sm font-medium text-dodger hover:text-midnight hover:underline ">
                                    Have Questions? Read our FAQs <svg className="ml-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                </a>
                                <a href="/" className="inline-flex items-center text-sm font-medium text-dodger hover:text-midnight hover:underline ">
                                    Book a FREE 30-minute demo session to try it out <svg className="ml-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                </a>
                                <a href="/" className="inline-flex items-center text-sm font-medium text-dodger hover:text-midnight hover:underline ">
                                    Read what our students and parents have to say <svg className="ml-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                </a> */}
                            </div>
                        </div>
                        <div>
                            <div className="flex justify-center items-center">
                                <span className="flex items-center text-2xl justify-center w-12 h-12 border-4 border-dodger bg-white text-dodger rounded-full shrink-0 ">
                                    2
                                </span>
                                <h3 className="mb-0 mx-4 text-2xl font-bold"> Redeem For A Class</h3>
                            </div>
                            <p className="text-sm mt-4">Exchange a ticket for any class at any time as long as its 2 weeks before the first session!</p>


                            <div className="text-left">

                                <ul className="pt-8 my-5 space-y-5 border-t border-gray-300 ">
                                    <li className="flex space-x-3">

                                        <svg className="flex-shrink-0 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                        <span className="text-base font-medium leading-tight text-gray-500">Pick a date and time that works for your schedule</span>
                                    </li>
                                    <li className="flex space-x-3">

                                        <svg className="flex-shrink-0 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                        <span className="text-base font-medium leading-tight text-gray-500"> Pick a subject and difficulty</span>
                                    </li>
                                    <li className="flex space-x-3">

                                        <svg className="flex-shrink-0 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                        <span className="text-base font-medium leading-tight text-gray-500">Register up to 2 weeks prior to start of class</span>
                                    </li>

                                    <li className="flex space-x-3">

                                        <svg className="flex-shrink-0 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                        <span className="text-base font-medium leading-tight text-gray-500">Let us know of any special accomodations </span>
                                    </li>
                                    <li className="flex space-x-3">

                                        <svg className="flex-shrink-0 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                        <span className="text-base font-medium leading-tight text-gray-500">Recieve your class and instructor information</span>
                                    </li>
                                </ul>

                                {/* <a href="/" className="inline-flex items-center text-sm font-medium text-dodger hover:text-midnight hover:underline ">
                                    Have A Ticket to Redeem? Choose a class here <svg className="ml-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                </a>
                                <a href="/" className="inline-flex items-center text-sm font-medium text-dodger hover:text-midnight hover:underline ">
                                    Buy A Ticket for a class here <svg className="ml-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                </a> */}
                            </div>

                        </div>

                        <div>
                            <div className="flex justify-center items-center">
                                <span className="flex items-center text-2xl justify-center w-12 h-12 border-4 border-dodger bg-white text-dodger rounded-full shrink-0 ">
                                    3
                                </span>
                                <h3 className="mb-0 mx-4 text-2xl font-bold">Enjoy Your Class</h3>
                            </div>
                            <p className="text-sm mt-4">Have fun making technology projects from scratch with the help of your instructor!</p>
                            <div className="text-left">
                                <ul className="pt-8 my-5 space-y-5 border-t border-gray-300 ">
                                    <li className="flex space-x-3">

                                        <svg className="flex-shrink-0 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                        <span className="text-base font-medium leading-tight text-gray-500">Have fun exploring a passion in tech</span>
                                    </li>
                                    <li className="flex space-x-3">

                                        <svg className="flex-shrink-0 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                        <span className="text-base font-medium leading-tight text-gray-500"> Build an awesome project with a live instructor</span>
                                    </li>
                                    <li className="flex space-x-3">

                                        <svg className="flex-shrink-0 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                        <span className="text-base font-medium leading-tight text-gray-500">Recieve mentorship from young professionals</span>
                                    </li>
                                    <li className="flex space-x-3">

                                        <svg className="flex-shrink-0 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                        <span className="text-base font-medium leading-tight text-gray-500">Develop practical tech skills</span>
                                    </li>


                                    <li className="flex space-x-3">

                                        <svg className="flex-shrink-0 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                        <span className="text-base font-medium leading-tight text-gray-500">Great resume builder!</span>
                                    </li>
                                </ul>

                                {/* <a href="/" className="inline-flex items-center text-sm font-medium text-dodger hover:text-midnight hover:underline ">
                                    Want to see who we have worked with? <svg className="ml-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                </a>
                                <a href="/" className="inline-flex items-center text-sm font-medium text-dodger hover:text-midnight hover:underline ">
                                    Book a FREE 30-minute demo session to try it out <svg className="ml-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                </a>
                                <a href="/" className="inline-flex items-center text-sm font-medium text-dodger hover:text-midnight hover:underline ">
                                    Read our blogs here <svg className="ml-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                </a> */}
                            </div>
                        </div>




                    </div>


                </div>
            </section>

        </div>
    );
}

export default ClassStructure;
// Ensure static generation
export async function getStaticProps() {
  return {
    props: {},
  };
}

import React from "react";
import NewsletterCTA from ".//UniversalComponents/NewsletterCTA";
import ContactForm from "./ContactComponents/ContactForm";

//import 'flowbite/dist/flowbite.min.js';

function Contact() {
    return (
        <div id='contact'>
            <ContactForm />
            <NewsletterCTA />
        </div>
    );
}

export default Contact;
// Ensure static generation
export async function getStaticProps() {
  return {
    props: {},
  };
}

import React from "react";

function Success() {
    return (
        <section className="bg-white py-40 text-center flex col-span-1 items-center">
            <div className="px-4 mx-auto max-w-screen-xl text-center lg:py-8 lg:px-12">
                <div id="alert-additional-content-3" className="p-4 mb-4 text-green-800 border border-green-300 rounded-lg bg-green-50 " role="alert">
                    <div className="items-center">
                        <svg className="mx-4 flex-shrink-0 w-5 h-5 text-green-600" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                        <span className="sr-only">Info</span>
                        <h3 className="text-lg font-medium">Success!</h3>
                    </div>
                    <div className="mt-2 mb-4 text-sm">
                        You have successfully purchased a ByteSize Learning Course. Please check your email for details. Feel free to contact us if you have any questions.
                    </div>
                    <div className="mx-auto">
                        <a href="/contact" type="button" className="text-white bg-green-800 hover:bg-green-900 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center   ">
                            Contact Us
                        </a>
                        <a type="button" href="/" className="text-green-800 bg-transparent border border-green-800 hover:bg-green-900 hover:text-white duration-200 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center    " data-dismiss-target="#alert-additional-content-3" aria-label="Close">
                            Home
                        </a>
                    </div>
                </div>
            </div>
        </section>

    );
}

export default Success;
// Ensure static generation
export async function getStaticProps() {
  return {
    props: {},
  };
}

import React from "react";
import ByteSizeLogo from "../../assets/bytesize-logo.png"
import 'flowbite/dist/flowbite.min.js';
import 'flowbite-react';
import { CodeBracketSquareIcon, CubeIcon, Cog6ToothIcon, FilmIcon, PhotoIcon, MusicalNoteIcon, CalendarDaysIcon, HeartIcon, FireIcon, BuildingLibraryIcon, ArrowTopRightOnSquareIcon  } from "@heroicons/react/24/solid";

function Navigation() {
    return (
        <header>
            <nav className="bg-white border-gray-200 px-4 lg:px-6 py-2.5 fixed z-30 w-full">
                <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
                    <a href="/" className="flex items-center">
                        <img src={ByteSizeLogo} className="mr-3 h-6 sm:h-9" alt="ByteSize Learning for kids and teens to learn tech skills Logo" />
                        <span className="self-center text-xl whitespace-nowrap hover:text-dodger duration-200">ByteSize Learning</span>
                    </a>
                    <div className="flex items-center lg:order-2">
                        {/* <a href="/redeem" className="md:block sm:hidden xs:hidden text-midnight hover:bg-gray-50 focus:ring-4 border-gray-400 focus:ring-gray-300 border-2 hover:border-midnight font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2  focus:outline-none ">Redeem a Ticket</a> */}
                        <a href="/buy" className="md:block sm:hidden xs:hidden text-white bg-dodger hover:bg-white hover:text-dodger duration-200 border-2 border-dodger focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2   focus:outline-none ">Buy a Class</a>

                        <button data-collapse-toggle="mobile-menu-2" type="button" className="text-center inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200  " aria-controls="mobile-menu-2" aria-expanded="false">
                            <span className="sr-only">Open main menu</span>
                            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
                            <svg className="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </div>

                    <div className="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1" id="mobile-menu-2">
                        <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                            <li>
                                <a href="/" className="block py-2 pr-4 pl-3 border-b border-gray-100 text-primary-600 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-600 lg:p-0 hover:text-dodger duration-200" aria-current="page">Home</a>
                            </li>
                            <li>
                                <a href="/about" className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-600 lg:p-0 hover:text-dodger duration-200">About</a>
                            </li>
                            <li>
                                <button id="mega-menu-button-classes" data-dropdown-toggle="mega-menu-classes" className="flex justify-between items-center py-2 pr-4 pl-3 w-full font-medium text-gray-700 border-b border-gray-100 lg:w-auto hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-600 lg:p-0 hover:text-dodger duration-200">Classes <svg className="ml-1 w-5 h-5 lg:w-4 lg:h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg></button>
                                <div id="mega-menu-classes" className="hidden absolute z-10 w-full bg-white border border-gray-100 shadow-md  lg:rounded-lg lg:w-auto lg:grid-cols-3">
                                    <div className="p-2 text-gray-900 bg-white lg:rounded-lg lg:col-span-2">
                                        <ul>
                                            <li>
                                                <a href="/programming" className="flex items-center p-3 rounded-lg hover:bg-gray-100 hover:text-dodger duration-200">
                                                    <div className="p-2 mr-4 bg-white rounded-lg shadow">
                                                        <CodeBracketSquareIcon className="h-8 text-dodger" />
                                                    </div>

                                                    <div>
                                                        <div className="font-semibold">Programming</div>
                                                        <div className="text-sm font-light text-gray-500">Kids - coding is the future, start now!</div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/engineering" className="flex items-center p-3 rounded-lg hover:bg-gray-100 hover:text-dodger duration-200">
                                                    <div className="p-2 mr-4 bg-white rounded-lg shadow">
                                                        <Cog6ToothIcon className="h-8 text-dodger" />
                                                    </div>
                                                    <div>
                                                        <div className="font-semibold">Engineering</div>
                                                        <div className="text-sm font-light text-gray-500">Unleash the engineer inside with Minecraft!</div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/gamedesign" className="flex items-center p-3 rounded-lg hover:bg-gray-100 hover:text-dodger duration-200">
                                                    <div className="p-2 mr-4 bg-white rounded-lg shadow">
                                                        <CubeIcon className="h-8 text-dodger" />
                                                    </div>
                                                    <div>
                                                        <div className="font-semibold">Game Design</div>
                                                        <div className="text-sm font-light text-gray-500">Future game developers start here!</div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/videoediting" className="flex items-center p-3 rounded-lg hover:bg-gray-100 hover:text-dodger duration-200">
                                                    <div className="p-2 mr-4 bg-white rounded-lg shadow">
                                                        <FilmIcon className="h-8 text-dodger" />
                                                    </div>
                                                    <div>
                                                        <div className="font-semibold">Video Editing</div>
                                                        <div className="text-sm font-light text-gray-500">Lights, camera, action! Make your movie now!</div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/musicproduction" className="flex items-center p-3 rounded-lg hover:bg-gray-100 hover:text-dodger duration-200">
                                                    <div className="p-2 mr-4 bg-white rounded-lg shadow">
                                                        <MusicalNoteIcon className="h-8 text-dodger" />
                                                    </div>
                                                    <div>
                                                        <div className="font-semibold">Music Production</div>
                                                        <div className="text-sm font-light text-gray-500">Making music makes magic!</div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/graphicdesign" className="flex items-center p-3 rounded-lg hover:bg-gray-100 hover:text-dodger duration-200">
                                                    <div className="p-2 mr-4 bg-white rounded-lg shadow">
                                                        <PhotoIcon className="h-8 text-dodger" />
                                                    </div>
                                                    <div>
                                                        <div className="font-semibold">Graphic Design</div>
                                                        <div className="text-sm font-light text-gray-500">Turn your creativity into digital designs!</div>
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>


                                    </div>
                                </div>
                            </li>
                            <li>
                                <button id="mega-menu-button-other" data-dropdown-toggle="mega-menu-other" className="flex justify-between items-center py-2 pr-4 pl-3 w-full font-medium text-gray-700 border-b border-gray-100 lg:w-auto hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-600 lg:p-0 hover:text-dodger duration-200:">More <svg className="ml-1 w-5 h-5 lg:w-4 lg:h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg></button>
                                <div id="mega-menu-other" className="hidden absolute z-10 w-full bg-white border border-gray-100 shadow-md  lg:rounded-lg lg:w-auto lg:grid-cols-3">
                                    <div className="p-2 text-gray-900 bg-white lg:rounded-lg lg:col-span-2">
                                        <ul>
                                            <li>
                                                <a href="/bytesizebuddies" className="flex items-center p-3 rounded-lg hover:bg-gray-100 hover:text-dodger duration-200">
                                                    <div className="p-2 mr-4 bg-white rounded-lg shadow">
                                                        <HeartIcon className="h-8 text-red-600" />
                                                    </div>
                                                    <div>
                                                        <div className="font-semibold">ByteSize Buddies Program</div>
                                                        <div className="text-sm font-light text-gray-500">Donate to help us close the digital divide!</div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://calendly.com/andrew-rust" target="_blank" rel="noopener noreferrer" className="flex items-center p-3 rounded-lg hover:bg-gray-100 hover:text-dodger duration-200 ">
                                                    <div className="p-2 mr-4 bg-white rounded-lg shadow">
                                                        <CalendarDaysIcon className="h-8 text-green-600" />
                                                    </div>
                                                    <div>
                                                        <span className="flex items-center"><div className="font-semibold">Book a FREE Demo <em className="text-xs">via Calendly</em></div>   <ArrowTopRightOnSquareIcon className="ml-2 h-4 text-midnight" /></span>
                                                        <div className="text-sm font-light text-gray-500">Let us help you decide which class is right for you</div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/partners" className="flex items-center p-3 rounded-lg hover:bg-gray-100 hover:text-dodger duration-200">
                                                    <div className="p-2 mr-4 bg-white rounded-lg shadow">
                                                        <BuildingLibraryIcon className="h-8 text-purple-500" />
                                                    </div>
                                                    <div>
                                                        <div className="font-semibold">Our Partners</div>
                                                        <div className="text-sm font-light text-gray-500">Hear who we work with in our community</div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.bytesizelearning.org/bytesizenews" className="flex items-center p-3 rounded-lg hover:bg-gray-100 hover:text-dodger duration-200 ">
                                                    <div className="p-2 mr-4 bg-white rounded-lg shadow">
                                                        <FireIcon className="h-8 text-orange-600" />
                                                    </div>
                                                    <div>
                                                        <div className="font-semibold">ByteSize News, Blogs & More</div>
                                                        <div className="text-sm font-light text-gray-500">See what else we're up to and how you can get involved.</div>
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>


                                    </div>
                                </div>
                            </li>
                            <li>
                                <a href="/contact" className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-600 lg:p-0 hover:text-dodger duration-200">Contact</a>
                            </li>
                            <li>
                                <div className="flex items-center space-between md:hidden sm:block mt-2">
                                    {/* <a href="/redeem" className="text-midnight hover:bg-gray-50 focus:ring-4 border-gray-400 focus:ring-gray-300 border-2 hover:border-midnight font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2  focus:outline-none ">Redeem a Ticket</a> */}
                                    <a href="/buy" className="text-white bg-dodger hover:bg-white hover:text-dodger duration-200 border-2 border-dodger focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2   focus:outline-none ">Buy a Class</a>
                                </div>
                            </li>
                        </ul>
                    </div>


                </div>
            </nav>
        </header>
    );
}

export default Navigation;

// Ensure static generation
export async function getStaticProps() {
  return {
    props: {},
  };
}

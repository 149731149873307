import React from "react";
import LCSPicture from "./../../assets/images/StudentImages/LCSPicture.jpeg"

function AboutHero() {
    return (
        <div id="about-hero">

                <img className="w-full object-cover md:h-[50vh] xs:h-[65vh] bg-cover bg-center" src={LCSPicture} alt="Children learning technology with ByteSize Learning for kids" />

                <div className="absolute top-0 left-0 w-full md:h-[50vh] xs:h-[65vh] bg-black bg-opacity-40" />
           
            <section className="absolute top-20 w-full bg-transparent object-center items-center">
                <div className="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-16 lg:px-6">
                    <dl className="grid max-w-screen-md gap-8 mx-auto sm:grid-cols-3 text-white mt-20">
                        <div className="flex flex-col items-center justify-center">
                            <dt className="mb-2 text-3xl md:text-4xl font-extrabold">120+</dt>
                            <dd className="font-light text-gray-100">ByteSize students and counting</dd>
                        </div>
                        <div className="flex flex-col items-center justify-center">
                            <dt className="mb-2 text-3xl md:text-4xl font-extrabold">50 million+</dt>
                            <dd className="font-light text-gray-100">Children in need of technology education</dd>
                        </div>
                        <div className="flex flex-col items-center justify-center">
                            <dt className="mb-2 text-3xl md:text-4xl font-extrabold">58%</dt>
                            <dd className="font-light text-gray-100">US High Schools have no technology curriculum</dd>
                        </div>
                    </dl>
                </div>
            </section>

        </div>
    );
}

export default AboutHero;
// Ensure static generation
export async function getStaticProps() {
  return {
    props: {},
  };
}

import React from "react";

function EULA() {
  return (
    <div id="eula" className="bg-gray-100 p-6">
      <div className="flex flex-col items-center justify-center content-center w-full md:w-90vh mx-auto">
        <div className="flex flex-col items-center justify-center content-center w-full md:w-90vh p-8">
          <h1 className="text-4xl font-bold text-center mt-32 mb-10">
            End User License Agreement (EULA)
          </h1>
          <p className="text-xl italic mb-4">
            for <a href="http://www.bytesizelearning.org" className="text-blue-500 underline">www.bytesizelearning.org</a>
          </p>
          
          <div className="mx-auto mt-8 text-lg space-y-4">
            <section>
              <h2 className="font-semibold">1. Acknowledgement and Acceptance</h2>
              <p>By using the Site, the User acknowledges and agrees to abide by the terms stated herein. Please retain a copy of these Terms for your records.</p>
            </section>
            <section>
              <h2 className="font-semibold">2. Service Description</h2>
              <p>ByteSize Learning offers online classes in programming, game design, and multimedia subjects, primarily during the summer, functioning as an E-Commerce platform.</p>
            </section>

            <section>
                <h2 className="font-semibold">3. Privacy</h2>
                <p>The User's interaction with the Site is governed by ByteSize Learning's Privacy Policy. Familiarize yourself with our data collection and processing procedures detailed therein.</p>
            </section>

            <section>
                <h2 className="font-semibold">4. Electronic Communications</h2>
                <p>Engaging with the Site or sending emails to ByteSize is considered electronic communication. You consent to receive electronic communications from us in return.</p>
            </section>

            <section>
                <h2 className="font-semibold">5. Protection of Minors</h2>
                <p>ByteSize collects data from children below thirteen to enhance their learning experience. Such users require parental consent to use the Site.</p>
            </section>

            <section>
                <h2 className="font-semibold">6. Refunds</h2>
                <p>Unsatisfied users may be eligible for a prorated to full refund. Address all refund requests to admin@bytesizelearning.org.</p>
            </section>

            <section>
                <h2 className="font-semibold">7. External Links</h2>
                <p>The Site may contain links to third-party websites ("Linked Sites"). These are for convenience and do not indicate endorsement. ByteSize Learning is not responsible for the content or operations of these Linked Sites.</p>
            </section>

            <section>
                <h2 className="font-semibold">8. Intellectual Property</h2>
                <p>Users are granted limited access rights to the Site. All content is ByteSize Learning's property, protected by relevant intellectual property laws.</p>
            </section>

            <section>
                <h2 className="font-semibold">9. International Access</h2>
                <p>The Site operates from the USA. Users accessing from other countries are responsible for compliance with their local laws.</p>
            </section>

            <section>
                <h2 className="font-semibold">10. Indemnity</h2>
                <p>Users agree to indemnify ByteSize Learning against any liabilities or costs arising from their use of the Site or violation of these Terms.</p>
            </section>

            <section>
                <h2 className="font-semibold">11. Dispute Resolution</h2>
                <p>All disputes related to these Terms will undergo binding arbitration per the Federal Arbitration Act.</p>
            </section>

            <section>
                <h2 className="font-semibold">12. Class Action Waiver</h2>
                <p>Arbitration will be conducted individually. Collective or class actions are not permitted.</p>
            </section>

            <section>
                <h2 className="font-semibold">13. Liability</h2>
                <p>ByteSize Learning provides the Site "as is", without any liability for damages arising from its use.</p>
            </section>

            <section>
                <h2 className="font-semibold">14. Termination</h2>
                <p>ByteSize Learning reserves the right to terminate any user's access to the Site.</p>
            </section>

            <section>
              <h2 className="font-semibold">15. Governing Law</h2>
              <p>These Terms are governed by North Carolina state laws. Any claims or disputes shall be resolved in accordance with North Carolina state law and must be raised within one year after the cause of action arises.</p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EULA;

// Ensure static generation
export async function getStaticProps() {
  return {
    props: {},
  };
}

import React from "react";

function TermsAndConditions() {
  return (
    <div id="termsandconditions" className="bg-gray-100 p-6">
      <div className="flex flex-col items-center justify-center content-center w-full md:w-90vh mx-auto">
        <div className="flex flex-col items-center justify-center content-center w-full md:w-90vh p-8">
          <h1 className="text-4xl font-bold text-dodger text-center mt-32 mb-10">
            Terms and Conditions
          </h1>
          <div className="mx-auto mt-8 text-lg">
            <p className="mb-6">
              We may revise and update these Terms from time to time in our sole
              discretion. All changes are effective immediately when we post
              them and apply to all access to and use of the services
              thereafter.
            </p>

            <section className="mb-6">
              <h2 className="text-2xl font-semibold mb-3">1. Introduction</h2>
              <p>
                Thank you for choosing ByteSize Learning. These terms and
                conditions (the "Terms") govern your access to and use of our
                services. By using our services, you agree to these Terms.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl font-semibold mb-3">
                2. Services Description
              </h2>
              <p>
                ByteSize Learning offers live online classes in programming,
                game design, and multimedia subjects primarily during the
                summer. The website functions as an e-commerce platform for
                service provision.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl font-semibold mb-3">
                3. Changes to the Terms
              </h2>
              <p>
                We may revise and update these Terms from time to time in our
                sole discretion. All changes are effective immediately when we
                post them and apply to all access to and use of the services
                thereafter.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl font-semibold mb-3">
                4. Accessing and Using the Services
              </h2>
              <p>
                We reserve the right to withdraw or amend the services we
                provide without notice. We will not be liable if for any reason
                all or any part of the services is unavailable. Engaging with
                our platform or sending emails to ByteSize is considered
                electronic communication. You consent to receive electronic
                communications from us in return.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl font-semibold mb-3">
                5. Intellectual Property Rights
              </h2>
              <p>
                The services and its entire contents, features, and
                functionality are owned by ByteSize Learning and are protected
                by relevant intellectual property laws. Users are granted
                limited access rights to the service.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl font-semibold mb-3">
                6. Restrictions on Use
              </h2>
              <p>
                You must not reproduce, distribute, modify, or create derivative
                works of any material from our services, with the following
                exceptions:
                <ul className="list-disc pl-6">
                  <li>
                    Your computer may temporarily store copies of materials in
                    RAM incidental to accessing and viewing those materials.
                  </li>
                  <li>
                    You may store files that are automatically cached by your
                    web browser for display enhancement purposes.
                  </li>
                </ul>
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl font-semibold mb-3">
                7. Protection of Minors
              </h2>
              <p>
                Users below the age of thirteen require parental consent to
                utilize our services. ByteSize collects specific data from such
                users to enhance the learning experience.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl font-semibold mb-3">8. Refunds</h2>
              <p>
                If you are unsatisfied with our services, you may be eligible
                for a prorated to full refund. Direct all refund inquiries to
                admin@bytesizelearning.org.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl font-semibold mb-3">9. External Links</h2>
              <p>
                Our service may contain links to third-party websites ("Linked
                Sites"). These are provided for convenience and do not indicate
                an endorsement. ByteSize Learning bears no responsibility for
                the content or operations of these Linked Sites.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl font-semibold mb-3">10. Termination</h2>
              <p>
                ByteSize Learning reserves the right to terminate any user's
                access to the services at its discretion, with or without
                notice.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl font-semibold mb-3">
                11. Governing Law and Dispute Resolution
              </h2>
              <p>
                These Terms are governed by North Carolina state laws. All
                disputes related to these Terms will be resolved through binding
                arbitration as per the Federal Arbitration Act. Collective or
                class actions are not permitted.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl font-semibold mb-3">
                12. Limitation on Liability
              </h2>
              <p>
                To the fullest extent provided by law, ByteSize Learning shall
                not be liable for any indirect, incidental, special,
                consequential, or punitive damages, or any loss of profits or
                revenues, whether incurred directly or indirectly, or any loss
                of data, use, goodwill, or other intangible losses.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl font-semibold mb-3">
                13. Waiver and Severability
              </h2>
              <p>
                No waiver by ByteSize Learning of any term or condition set out
                in these Terms shall be deemed a further or continuing waiver of
                such term or condition or a waiver of any other term or
                condition. If any provision of these Terms is deemed invalid,
                illegal, or unenforceable, the remainder of the Terms remains in
                effect.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl font-semibold mb-3">
                14. Contact Information
              </h2>
              <p>
                For questions or comments about these Terms, contact ByteSize
                Learning at: andrew@bytesizelearning.org.
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditions;

// Ensure static generation
export async function getStaticProps() {
  return {
    props: {},
  };
}

import React from "react";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import AndrewHeadshot2 from "../../assets/images/TeamHeadshots/AndrewHeadshot3.jpg";

function Team() {
    return (
        <section className="bg-white" id="team">
            <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6">
                <div className="mx-auto mb-0 lg:mb-16 max-w-screen-sm md:mb-24">
                    <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">Our Founder</h2>
                    <p className="font-light text-gray-500 sm:text-xl">Meet the founder and visionary behind ByteSize Learning</p>
                </div>
                <div className="grid md:grid-cols-3 gap-x-6 lg:gap-x-12 place-items-center">
                    <div className="mb-6 lg:mb-0">
                        <div className="bg-white block rounded-lg shadow-lg">
                            <div className="relative overflow-hidden bg-no-repeat bg-cover">
                                <img src={AndrewHeadshot2} className="w-full rounded-t-lg" alt="Andrew Rust: Founder & CEO of ByteSize Learning" />
                                <a href="https://www.linkedin.com/in/andrew-rust30/">
                                    <div className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed"></div>
                                </a>
                            </div>
                            <div className="p-6">
                                <h5 className="text-lg font-bold mb-4">Andrew Rust</h5>
                                <p className="text-gray-500 mb-4">Founder & CEO</p>
                                <ul className="list-inside flex mx-auto justify-center">

                                    <a href="/contact" className="px-2">
                                        <EnvelopeIcon className="h-8 text-dodger" />

                                    </a>
                                    <a href="https://www.linkedin.com/in/andrew-rust30/" className="px-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="w-8 h-8 text-dodger">
                                            <path fill="currentColor"
                                                d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
                                        </svg>
                                    </a>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className='col-span-2'>
                        <h2 className="mb-4 text-2xl tracking-tight font-extrabold text-gray-600">About</h2>
                        <p className="mb-6 text-left">Andrew founded ByteSize Learning in 2020 after recognizing the growing technology education disparity present in his home state of North Carolina. He began ByteSize as a tutoring service for peers until transitioning to provide supplemental education for children in earlier stages of their academic and professional careers.</p>
                            <p className="mb-6 text-left">Andrew is originally from New Bern, North Carolina and attended New Bern High School before attending the North Carolina School of Science and Mathematics (NCSSM) in Durham, NC</p>
                            <p className="mb-6 text-left">Andrew is current Master of Science in Computer Engineering at Columbia University. He obtained a B.S at Wake Forest University in Winston-Salem, NC where he studied Engineering, Computer Science, and Mathematics. He is also a former college athlete for the Demon Deacons as a right-handed pitcher for the varsity baseball team.</p>
                        
                        <div className="mt-16 rounded-lg">
                            <h2 className="mb-4 text-2xl tracking-tight font-extrabold text-gray-600">Message From Andrew</h2>
                            <svg className="mb-3 h-8 text-dodger" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor" />
                            </svg>
                            <p className="text-left mb-3 text-lg font-light leading-relaxed text-gray-500">"Education is the future! As a former public school student in Eastern North Carolina - I experienced first-hand the many deficiencies that kids around the US face."</p>
                            <p className="text-left mb-3 text-lg font-light leading-relaxed text-gray-500">"ByteSize Learning let's me give to kids growing up what I didn't have: high-quality education in highly technical fields"</p>

                        </div>
                    </div>


                </div>
            </div>
        </section>




    );
}

export default Team;
// Ensure static generation
export async function getStaticProps() {
  return {
    props: {},
  };
}

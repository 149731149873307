import React from "react";

function WebsiteDisclaimer() {
  return (
    <div id="websitedisclaimer" className="bg-gray-100 p-6">
      <div className="flex flex-col items-center justify-center content-center w-full md:w-90vh mx-auto">
        <div className="flex flex-col items-center justify-center content-center w-full md:w-90vh p-8">
          <h1 className="text-4xl font-bold text-center mt-32 mb-10">
            Website Disclaimer
          </h1>
          <p className="text-xl italic mb-4">
            for <a href="http://www.bytesizelearning.org" className="text-blue-500 underline">www.bytesizelearning.org</a>
          </p>
          
          <div className="mx-auto mt-8 text-lg">
            <p className="mb-6">
              The information provided by ByteSize Learning on <a href="http://www.bytesizelearning.org" className="text-blue-500 underline">www.bytesizelearning.org</a> is for general informational purposes only. All information on the site is provided in good faith, however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the site.
            </p>

            <p className="mb-6">
              Under no circumstance shall we have any liability to you for any loss or damage of any kind incurred as a result of the use of the site or reliance on any information provided on the site. Your use of the site and your reliance on any information on the site is solely at your own risk.
            </p>

            <p className="mb-6">
              The site may contain links to other websites or content belonging to or originating from third parties. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability, or completeness by us.
            </p>

            <p className="mb-6">
              For any specific concerns, please consult with an appropriate professional before making decisions based on content we provide.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WebsiteDisclaimer;

// Ensure static generation
export async function getStaticProps() {
  return {
    props: {},
  };
}

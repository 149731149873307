import React from "react";

function PrivacyPolicy() {
  return (
    <div id="privacypolicy" className="bg-gray-100 p-6">
      <div className="flex flex-col items-center justify-center content-center w-full md:w-90vh mx-auto">
        <div className="flex flex-col items-center justify-center content-center w-full md:w-90vh p-8">
          <h1 className="text-4xl font-bold text-center mt-32 mb-10">
            ByteSize Learning Privacy Policy
          </h1>
          <p className="font-bold text-xl mb-4">
            Effective Date: September 1st, 2023
          </p>

          <div className="mx-auto mt-8 text-lg">
            <section className="mb-6">
              <h2 className="text-2xl font-semibold mb-3">1. Introduction</h2>
              <p>
                Welcome to ByteSize Learning. We respect the privacy of our
                users and are committed to protecting it through our compliance
                with this policy. This policy describes the types of information
                we may collect from you or that you may provide to us and our
                practices for collecting, using, maintaining, protecting, and
                disclosing that information.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl font-semibold mb-3">
                2. Information We Collect and How We Collect It
              </h2>
              <p>
                We collect several types of information from and about users of
                our services, including:
              </p>
              <ul className="list-disc pl-6">
                <li>
                  Personal data, such as name, postal address, e-mail address,
                  telephone number, or any other identifier by which you may be
                  contacted online or offline.
                </li>
                <li>
                  Non-personal identification information about your internet
                  connection, the equipment you use to access our services, and
                  usage details.
                </li>
              </ul>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl font-semibold mb-3">
                3. How We Use Your Information
              </h2>
              <p>
                We use information that we collect about you or that you provide
                to us, including any personal data:
              </p>
              <ul className="list-disc pl-6">
                <li>To present our services to you.</li>
                <li>To provide you with notices about your account.</li>
                <li>
                  To notify you about changes to our services or any products or
                  services we offer or provide through it.
                </li>
                <li>
                  To allow you to participate in interactive features on our
                  services.
                </li>
                <li> For any other purpose with your consent. </li>
              </ul>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl font-semibold mb-3">
              4. Disclosure of Your Information
              </h2>
              <p>
              We may disclose aggregated information about our users and information that does not identify any individual without restriction. We may disclose personal data that we collect or you provide as described in this privacy policy:
              </p>
              <ul className="list-disc pl-6">
                <li>
                To our subsidiaries and affiliates.
                </li>
                <li>
                To contractors, service providers, and other third parties we use to support our business.
                </li>
                <li>
                For any other purpose disclosed by us when you provide the information.
                </li>
                <li>
                With your consent.
                </li>
              </ul>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl font-semibold mb-3">
                5. Protection of Minors
              </h2>
              <p>
                ByteSize collects specific information from children below
                thirteen years to enrich the learning experience. Users below
                thirteen require parental consent to utilize the Site. Parents
                with concerns can contact us as detailed at the end of this
                policy.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl font-semibold mb-3">6. Data Security</h2>
              <p>
                We have implemented measures designed to secure your personal
                data from accidental loss and from unauthorized access, use,
                alteration, and disclosure. The transmission of information via
                the internet is not entirely secure, and while we do our best,
                we cannot guarantee the security of your personal data
                transmitted to our services.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl font-semibold mb-3">
                7. Changes to Our Privacy Policy
              </h2>
              <p>
                We may update our privacy policy from time to time. All changes
                are effective immediately when we post them. Your continued use
                of our services after the changes have been made is considered
                acceptance of those changes, so please check the policy
                periodically for updates.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl font-semibold mb-3">
                8. Contact Information
              </h2>
              <p>
                For any questions, concerns, or comments regarding this privacy
                policy and our privacy practices, contact us at:
                andrew@bytesizelearning.org.
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;

// Ensure static generation
export async function getStaticProps() {
  return {
    props: {},
  };
}

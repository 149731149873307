import React from "react";

function Story() {
    return (
        <div id="story">
            <section className="bg-white w-full flex-grow">
                <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
                    <div className="font-light text-gray-500 sm:text-lg">
                        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">Empowering, not impoverishing children with technology</h2>
                        <p className="mb-4">At ByteSize Learning, we create future opportunity and personal growth through education, mentorship, and support so that students can develop passions using technology tools</p>
                        <a href="/contact" className="inline-flex items-center font-medium text-dodger hover:text-midnight ">
                            Reach out to us
                            <svg className="ml-1 w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                        </a>
                    </div>
                    <div className="mt-4 font-light text-gray-500 sm:text-lg lg:mt-0">
                        <p className="mb-4">We put quality first and ensure that each of our students has a positive experience working with us. We know how difficult it can be to discover and support your child's interest and we do so through the technology they know and love.</p>
                        <p>Take the first step into making your child a passionate learner in one of our introductory technology classes, taught by young professionals from across the US.</p>
                    </div>
                </div>
            </section>


        </div>
    );
}

export default Story;
// Ensure static generation
export async function getStaticProps() {
  return {
    props: {},
  };
}

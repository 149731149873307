import { useState, useEffect } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Spinner } from 'flowbite-react';

function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [productList, setProductList] = useState([]);
  const [priceList, setPriceList] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState('');

  const [discount, setDiscount] = useState(''); // Add discount state variable

  useEffect(() => {
    // Retrieve the list of products from Stripe - send request to backend: /stripe-products
    const fetchProducts = async () => {
      const response = await fetch('https://api.bytesizelearning.org/stripe-products');
      const responseData = await response.json();
      const products = responseData.data;
      setProductList(products);
      setSelectedProduct(products[0]);
    }
    fetchProducts();

    //Retrieve the list of prices from the backend - send request to backend: /prices
    const fetchPrices = async () => {
      const response = await fetch('https://api.bytesizelearning.org/prices');
      const responseData = await response.json();
      const prices = responseData.data;
      setPriceList(prices);
      setSelectedPrice(prices[0]);
    }
    fetchPrices();

  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    const amount = selectedPrice ? selectedPrice.unit_amount / 100 : 0;

    if (error) {
      setMessage(error.message);
      setIsLoading(false);
    } else {
      // Send payment method and email to your server to create a charge
      const response = await fetch('https://api.bytesizelearning.org/charge', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          payment_method_id: paymentMethod.id,
          email: email,
          amount: amount, // Stripe expects the amount in cents
          discount: discount // Add discount to the request body
        })
      });

      const data = await response.json();

      const clientSecret = data.clientSecret;

      console.log("Payment Intent created successfully! A charge of " + amount + " will be made to " + email + "  with the discount code " + discount + " using the payment method " + paymentMethod.id + ".");
      console.log('[clientSecret]', clientSecret);
      console.log('[paymentMethod]', paymentMethod);

      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethod.id,
      });

      if (result.error) {
        setMessage(result.error.message);
        setIsLoading(false);
      }
      else {
        setMessage('Payment successful!');
        setIsLoading(false);
      }

      console.log('[PaymentIntent]', result.paymentIntent);

      //redirect the user to the thank you page
      window.location.href = "https://www.bytesizelearning.org/success";

    }
  };

  const handleProductChange = (event) => {
    // Find the product and price that match the selected values using the data retrieved from the backend/Stripe
    const productName = event.target.value;
    const product = productList.find((p) => p.name === productName);
    const price = priceList.find((p) => p.product === product.id);
    setSelectedPrice(price);
    setSelectedProduct(product);

    let discountPercentage = 0;
    if (discount === "BUYCLASSTESTFREE") {
      console.log("100% off discount applied!");
      discountPercentage = 1;
    } else if (discount === "BUYCLASSTEST10") {
      console.log("90% off discount applied!");
      discountPercentage = 0.9;
    } else if (discount === "BUYCLASSTEST01") {
      console.log("99.5% off discount applied!");
      discountPercentage = 0.995;
    } else {
      console.log("No discount applied.");
    }

    const discountedPrice = price.unit_amount * (1 - discountPercentage) / 100;
    const formattedPrice = discountedPrice.toFixed(2);
    setTotalAmount(formattedPrice);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="py-4">
        <label htmlFor="email" className="font-semibold text-gray-600">
          Email Address:
        </label>
        <input
          type="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="mt-2 px-4 py-2 w-full border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
        />
        <label htmlFor="discount" className="font-semibold text-gray-600">
          Discount Code:
        </label>
        <input
          type="text"
          name="discount"
          value={discount}
          onChange={(e) => setDiscount(e.target.value)}
          className="mt-2 px-4 py-2 w-full border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
        />
      </div>

      <div className="py-4">
        <span className='flex items-center'>
          <label htmlFor="product" className="font-semibold text-gray-600 mx-4">
            Select a product:
          </label>
          <p className={selectedProduct ? 'text-dodger' : ''}>
            {selectedProduct ? selectedProduct.name : 'None'}
          </p>
        </span>
        <select
          name="product"
          value={selectedProduct}
          onChange={handleProductChange}
          className="mt-2 px-4 py-2 w-full border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
        >
          <option value="">Select a Product</option>
          {productList.map((product) => (
            <option key={product.id} value={product.name}>
              {product.name}
            </option>
          ))}
        </select>
      </div>


      <div className="py-4">
        <label htmlFor="card" className="font-semibold text-gray-600">
          Card Information:
        </label>
        <div className="mt-2 p-4 border border-gray-300 rounded-md">
          <CardElement />
        </div>
      </div>

      <div className="py-4">
        <label className="font-semibold text-gray-600">Total Amount:</label>
        <p className="mt-2">${totalAmount}</p>
      </div>
      <div className="py-4">
        <button
          type="submit"
          className="bg-dodger text-white font-semibold py-2 px-4 rounded-md"
          disabled={!stripe || isLoading || !selectedProduct}
        >
          {isLoading ? <span>Processing... <Spinner animation="border" size="sm" /></span> : 'Pay now'}
        </button>
      </div>
      {message && <div className="text-red-500">{message}</div>}
    </form>
  );
}

export default CheckoutForm;
// Ensure static generation
export async function getStaticProps() {
  return {
    props: {},
  };
}

import React from "react";
import GraphicDesignHeroVideo from "../../assets/videos/GraphicDesignHeroVideo.mp4";
import ArrowDown from "../../assets/icons/arrow-down.png";
import { PresentationChartLineIcon, AcademicCapIcon } from "@heroicons/react/24/solid";
import { Badge, Carousel, Tabs } from "flowbite-react";

import GraphicDesignProject1 from "../../assets/images/GraphicDesign/GraphicDesignProject1.jpg";
import GraphicDesignProject2 from "../../assets/images/GraphicDesign/GraphicDesignProject2.jpg";
import GraphicDesignProject3 from "../../assets/images/GraphicDesign/GraphicDesignProject3.jpg";
import GraphicDesignProject4 from "../../assets/images/GraphicDesign/GraphicDesignProject4.jpg";
import GraphicDesignProject5 from "../../assets/images/GraphicDesign/GraphicDesignProject5.jpg";
import GraphicDesignProject6 from "../../assets/images/GraphicDesign/GraphicDesignProject6.jpg";
import GraphicDesignProject7 from "../../assets/images/GraphicDesign/GraphicDesignProject7.jpg";

import NewsletterCTA from "../UniversalComponents/NewsletterCTA.jsx";
import OtherClasses from "./OtherClasses.jsx";
import CoursePerks from "./CoursePerks";
import BookDemo from "../UniversalComponents/BookDemo";

import ScratchGameDesignThumbnail from "../../assets/thumbnails/ScratchGameDesignThumbnail.jpg";
import VideoThumbnail from "../../assets/thumbnails/video-thumbnail.png";
import ScratchLogo from "../../assets/icons/scratch-logo.png";
import VideoLogo from "../../assets/icons/video-logo.png";
import MusicLogo from "../../assets/icons/music-logo.png";
import MusicThumbnail from "../../assets/thumbnails/music-thumbnail.jpg";

function GraphicDesign() {
    return (
        <div id="graphicdesign">
            <div className="w-full h-[75vh] content-center">
                <video
                    className="w-full h-full object-cover z-0"
                    src={GraphicDesignHeroVideo}
                    playsInline
                    autoPlay
                    loop
                    muted
                />
                <div className="absolute top-0 left-0 w-full h-[75vh] bg-black bg-opacity-40" />
                <div className="absolute top-0 w-full h-[75vh] flex items-center justify-left z-0 bg-transparent">
                    <div className="grid w-full lg:gap-8 xl:gap-0 lg:py-16 bg-transparent content-around mx-20">
                        <div className="mr-auto place-self-center lg:col-span-7">
                            <h1 className="max-w-full mb-6 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl text-white">
                                Graphic Design
                            </h1>
                            <p className="max-w-2xl mb-6 font-light lsg:mb-8 md:text-lg lg:text-xl text-white">
                                Learn how to edit photos, create graphics, and design artwork in GIMP!
                            </p>
                            <a
                                href="/buy"
                                className="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center border-2 border-dodger text-white rounded-lg bg-dodger hover:bg-transparent hover:border-solid hover:border-2 hover:border-white focus:ring-4 focus:ring-dodger ">
                                Buy this class!
                                <svg
                                    className="w-5 h-5 ml-2 -mr-1"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fillRule="evenodd"
                                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                            </a>
                        </div>
                    </div>
                    <div className="absolute w-full bottom-0 flex items-center justify-center z-10 bg-transparent animate-bounce place-self-center mb-5">
                        <div>
                            <a href="#learn-more-about-graphic-design">
                            <p className="text-white font-light mx-6">Learn more about graphic design!</p>
                            </a>
                        </div>
                        <div>
                        <a href="#learn-more-about-graphic-design">
                            <img className="w-8 h-8" viewBox="0 0 24 24" src={ArrowDown} alt="arrow down to learn more about ByteSize Learning for kids" />
                        </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="learn-more-about-graphic-design"/>
            <div className="py-8 px-4 mx-auto max-w-screen-xl text-center sm:py-16 lg:px-6">
                <div className="mt-8 lg:mt-12 space-y-8 md:grid md:grid-cols-5 lg:grid-cols-5 md:gap-12 md:space-y-0">
                    <div className="col-span-3">
                        <div className="h-full">
                            <Carousel>
                                <img
                                    className="h-full object-cover"
                                    src={GraphicDesignProject1}
                                    alt="Graphic Design and Photograph Editing in GIMP with ByteSize Learning Project 1"
                                />

                                <img
                                    className="h-full object-cover"
                                    src={GraphicDesignProject2}
                                    alt="Graphic Design and Photograph Editing in GIMP with ByteSize Learning Project 2"
                                />
                                <img
                                    className="h-full object-cover"
                                    src={GraphicDesignProject3}
                                    alt="Graphic Design and Photograph Editing in GIMP with ByteSize Learning Project 3"
                                />
                                <img
                                    className="h-full object-cover"
                                    src={GraphicDesignProject4}
                                    alt="Graphic Design and Photograph Editing in GIMP with ByteSize Learning Project 4"
                                />
                                <img
                                    className="h-full object-cover"
                                    src={GraphicDesignProject5}
                                    alt="Graphic Design and Photograph Editing in GIMP with ByteSize Learning Project 5"
                                />
                                <img
                                    className="h-full object-cover"
                                    src={GraphicDesignProject6}
                                    alt="Graphic Design and Photograph Editing in GIMP with ByteSize Learning Project 6"
                                />
                                <img
                                    className="h-full object-cover"
                                    src={GraphicDesignProject7}
                                    alt="Graphic Design and Photograph Editing in GIMP with ByteSize Learning Project 7"
                                />
                            </Carousel>
                        </div>
                    </div>
                    <div className="col-span-2">
                        <h2 className="mb-4 tracking-tight font-extrabold text-gray-900  text-4xl">Graphic Design in GIMP</h2>
                        <Tabs.Group
                            aria-label="Pills"
                            className="fullWidth pills flex bg-transparent my-4"
                        >
                            <Tabs.Item
                                active={true}
                                title={<span className="text-green-600 text-xl flex items-center"><AcademicCapIcon className="mx-2 w-8 h-8 text-green-600" />Introductory</span>}
                            >
                                <hr className="mb-4 border-green-600 " />
                                <ul className="my-0 lg:mb-0 space-y-4 text-left">
                                    <li className="flex space-x-2.5">

                                        <svg className="flex-shrink-0 w-5 h-5 text-green-600" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                        <span className="leading-tight text-gray-500">User interface overview and basic editing tools</span>
                                    </li>
                                    <li className="flex space-x-2.5">

                                        <svg className="flex-shrink-0 w-5 h-5 text-green-600" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                        <span className="leading-tight text-gray-500">Cutting, copying, pasting</span>
                                    </li>
                                    <li className="flex space-x-2.5">

                                        <svg className="flex-shrink-0 w-5 h-5 text-green-600" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                        <span className="leading-tight text-gray-500">Layers and project organization</span>
                                    </li>
                                    <li className="flex space-x-2.5">

                                        <svg className="flex-shrink-0 w-5 h-5 text-green-600" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                        <span className="leading-tight text-gray-500">Retouching, file reformatting and exporting</span>
                                    </li>
                                    <div className="flex flex-wrap">
                                        <Badge color="success"> Recommended for ages: 8-14</Badge>
                                    </div>
                                </ul>
                            </Tabs.Item>

                            <Tabs.Item
                                color="yellow"
                                title={<span className="text-yellow-600 text-xl flex items-center"><PresentationChartLineIcon className="mx-2 w-8 h-8 text-yellow-600" />Intermediate</span>}
                            >
                                <hr className="mb-4 border-yellow-600 " />
                                <ul className="my-0 lg:mb-0 space-y-4 text-left">
                                    <li className="flex space-x-2.5">
                                        <svg className="flex-shrink-0 w-5 h-5 text-yellow-600" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                        <span className="leading-tight text-gray-500">Advanced editing, transformation and retouching tools</span>
                                    </li>
                                    <li className="flex space-x-2.5">

                                        <svg className="flex-shrink-0 w-5 h-5 text-yellow-600" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                        <span className="leading-tight text-gray-500">Shadows and perspective enhacement</span>
                                    </li>
                                    <li className="flex space-x-2.5">

                                        <svg className="flex-shrink-0 w-5 h-5 text-yellow-600" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                        <span className="leading-tight text-gray-500">Lighting, contrast and color correction</span>
                                    </li>
                                    <li className="flex space-x-2.5">

                                        <svg className="flex-shrink-0 w-5 h-5 text-yellow-600" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                        <span className="leading-tight text-gray-500">Advanced canvas and file settings</span>
                                    </li>
                                    <div className="flex flex-wrap">
                                        <Badge color="warning"> Recommended for ages: 10-16</Badge>
                                    </div>
                                </ul>
                            </Tabs.Item>
                        </Tabs.Group>

                        <p className="mb-4 font-light text-gray-500 md:text-2xl lg:text-3xl text-xl xl:text-4xl">$250</p>
                        <div className="mx-auto max-w-screen-sm text-center items-center content-center my-6 w-full flex flex-col">
                            <a href="https://calendly.com/andrew-rust" className="w-full my-2 text-midnight hover:bg-gray-50 focus:ring-4 border-gray-400 focus:ring-gray-300 border-2 hover:border-gray-900 font-medium rounded-lg text-lg px-4 py-2 lg:px-5 lg:py-2.5 mr-2  focus:outline-none ">Book a Demo</a>
                            <a href="/buy" className="w-full my-2 text-white bg-dodger hover:bg-white hover:text-dodger duration-200 border-2 border-dodger focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-lg px-4 py-2 lg:px-5 lg:py-2.5 mr-2   focus:outline-none ">Buy Class</a>

                            <a href="/contact" className="my-2 inline-flex items-center text-sm font-semibold text-dodger hover:underline">
                                Ask us a question
                                <svg className="ml-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            </a>
                        </div>

                    </div>
                </div>
            </div>
            <CoursePerks />
            <section className="bg-white">
                <div className="py-0 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6 ">
                    <h2 className="mb-6 lg:mb-8 text-3xl lg:text-4xl tracking-tight font-extrabold text-center text-gray-900">Frequently asked questions</h2>
                    <div className="mx-auto max-w-screen-md">
                        <div id="accordion-flush" data-accordion="collapse" data-active-classes="bg-white text-gray-900" data-inactive-classes="text-gray-500">
                            <h2 id="accordion-flush-heading-1">
                                <button type="button" className="flex justify-between items-center py-5 w-full font-medium text-left text-gray-900 bg-white border-b border-gray-200 " data-accordion-target="#accordion-flush-body-1" aria-expanded="true" aria-controls="accordion-flush-body-1">
                                    <span>
                                        <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900">
                                            <svg className="flex-shrink-0 mr-2 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path></svg>
                                            Why GIMP?
                                        </h3>
                                    </span>
                                    <svg data-accordion-icon="" className="w-6 h-6 rotate-180 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </button>
                            </h2>
                            <div id="accordion-flush-body-1" className="" aria-labelledby="accordion-flush-heading-1">
                                <div className="py-5 border-b border-gray-200 ">
                                    <p className="text-gray-500">Gimp is a free, open-source graphics and image editor that can be used to adjust pictures, create graphics or thumbnails and even create digital artwork. Gimp is a free alternative to photoshop and is just as powerful as they contain many of the same capabilities. </p>
                                </div>
                            </div>
                            <h2 id="accordion-flush-heading-2">
                                <button type="button" className="flex justify-between items-center py-5 w-full font-medium text-left text-gray-500 border-b border-gray-200 " data-accordion-target="#accordion-flush-body-2" aria-expanded="false" aria-controls="accordion-flush-body-2">
                                    <span>
                                        <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900">
                                            <svg className="flex-shrink-0 mr-2 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path></svg>
                                            Who is this class for?
                                        </h3>
                                    </span>
                                    <svg data-accordion-icon="" className="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </button>
                            </h2>
                            <div id="accordion-flush-body-2" className="hidden" aria-labelledby="accordion-flush-heading-2">
                                <div className="py-5 border-b border-gray-200 ">
                                    <p className="text-gray-500">This class is perfect for budding photographers and lovers of art!</p>
                                    <ul className="pt-0 my-7 space-y-5 ">
                                        <li className="flex space-x-3">

                                            <svg className="flex-shrink-0 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                            <span className="text-sm font-medium leading-tight text-gray-500">Interested in digital content creation or photo editing</span>
                                        </li>
                                        <li className="flex space-x-3">

                                            <svg className="flex-shrink-0 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                            <span className="text-sm font-medium leading-tight text-gray-500">Develop professional skills in photo editing and graphical design</span>
                                        </li>
                                        <li className="flex space-x-3">

                                            <svg className="flex-shrink-0 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                            <span className="text-sm font-medium leading-tight text-gray-500">Create awesome designs, logos, and artwork to share with friends and family!</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <BookDemo />

            <div className="mx-auto my-8 max-w-screen-lg text-center items-center content-center">
                <h1 className="mb-4 text-5xl tracking-tight font-extrabold leading-tight text-gray-900">Similar Classes</h1>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 xl:grid-cols-3">
                    <article className="hidden p-4 mx-auto max-w-sm bg-white rounded-lg shadow-md border border-gray-200  sm:block">
                        <a href="/videoediting">
                            <img className="mb-5 rounded-lg" src={VideoThumbnail} alt="Video Editing and Production in Shotcut with ByteSize Learning for kids" />
                        </a>
                        <div className="flex items-center mb-3 space-x-2">
                            <img className="w-16 h-12 rounded-lg" src={VideoLogo} alt="Video Editing and Production in Shotcut logo with ByteSize Learning for kids" />
                            <div className="font-medium">
                                <div>Shotcut</div>
                            </div>
                        </div>
                        <h3 className="mb-2 text-xl font-bold tracking-tight text-gray-900 lg:text-2xl h-10">
                            <a className="text-xl" href="/videoediting">Video Editing in Shotcut</a>
                        </h3>
                        <p className="mb-3 font-light text-gray-500 h-18">Develop your skills in video editing and production! Create your own video edits and so much more in Shotcut!</p>
                        <div className="flex items-center justify-evenly">
                            <a href="/videoediting" className="inline-flex items-center text-midnight bg-transparent hover:bg-midnight hover:text-white duration-200 border-2 border-midnight focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2   focus:outline-none ">Read Details
                            </a>
                            <a href="/buy" className="inline-flex items-center text-white bg-dodger hover:bg-white hover:text-dodger duration-200 border-2 border-dodger focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2   focus:outline-none ">Buy Now!
                            </a>
                        </div>
                    </article>
                    <article className="p-4 mx-auto max-w-sm bg-white rounded-lg shadow-md border border-gray-200  sm:block">
                        <a href="/musicproduction">
                            <img className="mb-5 rounded-lg" src={MusicThumbnail} alt="Music Production and Composition in Bandlab with ByteSize Learning for kids" />
                        </a>
                        <div className="flex items-center mb-3 space-x-2">
                            <img className="w-16 h-12 rounded-lg" src={MusicLogo} alt="Music Production and Composition in Bandlab logo with ByteSize Learning for kids" />
                            <div className="font-medium">
                                <div>Bandlab</div>
                            </div>
                        </div>
                        <h3 className="mb-2 text-xl font-bold tracking-tight text-gray-900 lg:text-2xl h-10">
                            <a className="text-xl" href="/musicproduction">Music Production in Bandlab</a>
                        </h3>
                        <p className="mb-3 font-light text-gray-500 h-18">Learn how to create your own music in Cakewalk. Learn the basics of music production.</p>
                        <div className="flex items-center justify-evenly">
                            <a href="/musicproduction" className="inline-flex items-center text-midnight bg-transparent hover:bg-midnight hover:text-white duration-200 border-2 border-midnight focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2   focus:outline-none ">Read Details
                            </a>
                            <a href="/buy" className="inline-flex items-center text-white bg-dodger hover:bg-white hover:text-dodger duration-200 border-2 border-dodger focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2   focus:outline-none ">Buy Now!
                            </a>
                        </div>
                    </article>
                    <article className="p-4 mx-auto max-w-sm bg-white rounded-lg shadow-md border border-gray-200 ">
                        <a href="/gamedesign">
                            <img className="mb-5 rounded-lg" src={ScratchGameDesignThumbnail} alt="Programming, Game Design and Game Development in Scratch with ByteSize Learning for kids" />
                        </a>
                        <div className="flex items-center mb-3 space-x-2">
                            <img className="w-16 h-12 rounded-lg" src={ScratchLogo} alt="Programming, Game Design and Game Development in Scratch with ByteSize Learning for kids" />
                            <div className="font-medium">
                                <div>Scratch</div>
                            </div>
                        </div>
                        <h3 className="mb-2 text-xl font-bold tracking-tight text-gray-900 lg:text-2xl h-10">
                            <a className="text-xl" href="/gamedesign">Game Design in Scratch</a>
                        </h3>
                        <p className="mb-3 font-light text-gray-500 h-18">Learn the fundamentals of game design through the use of Scratch and other programming tools!</p>

                        <div className="flex items-center justify-evenly">
                            <a href="/gamedesign" className="inline-flex items-center text-midnight bg-transparent hover:bg-midnight hover:text-white duration-200 border-2 border-midnight focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2   focus:outline-none ">Read Details
                            </a>
                            <a href="/buy" className="inline-flex items-center text-white bg-dodger hover:bg-white hover:text-dodger duration-200 border-2 border-dodger focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2   focus:outline-none ">Buy Now!
                            </a>
                        </div>

                    </article>

                </div>
            </div>
            <OtherClasses />
            <NewsletterCTA />
        </div>
    );
}

export default GraphicDesign;


// Ensure static generation
export async function getStaticProps() {
  return {
    props: {},
  };
}

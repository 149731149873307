import React from "react";

function BuddiesFAQS() {
    return (
        <div>
            <section className="bg-white">
  <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
      <h2 className="mb-8 text-4xl tracking-tight font-extrabold text-gray-900">Frequently asked questions</h2>
      <div className="grid pt-8 text-left border-t border-gray-200 md:gap-16  md:grid-cols-2">
          <div>
              <div className="mb-10">
                  <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900">
                      <svg className="flex-shrink-0 mr-2 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path></svg>
                      What is the ByteSize Buddies Program?
                  </h3>
                  <p className="text-gray-500">The ByteSize Buddies program is an impact-oriented initiative on behalf of ByteSize Learning to provide resources, both physical and educational, to children who can benefit greatly from our work. We provide this effort to them at no-cost and hope to be influencial in impacting other organizations and individuals to follow suit.</p>
              </div>
              <div className="mb-10">                        
                  <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900">
                      <svg className="flex-shrink-0 mr-2 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path></svg>
                      Who are ByteSize Buddies? How do you select them?
                  </h3>
                  <p className="text-gray-500">We look for students residing in underserved areas across the US - specifically in our home state of North Carolina. We work with public schools in those areas that lack funding for technology equipment and instruction where we mentor students with great potential in a future in technology who lack the resources to pursue their interests. </p>
              </div>
              <div className="mb-10">
                  <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900">
                      <svg className="flex-shrink-0 mr-2 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path></svg>
                      Who are the mentors? How does that work?
                  </h3>
                  <p className="text-gray-500 mb-4">We find mentors through our network of qualified instructors and through referrals of trusted advisors. Mentors are vetted and participate in the program willingly out of interest in helping the next generation find a good career path</p>
                  <p className="text-gray-500">We require that the ByteSize buddy maintain in regular contact with their mentor for at least 18 months after being admitted although we hope to have made a connection for life!</p>
              </div>
          </div>
          <div>
              <div className="mb-10">
                  <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900">
                      <svg className="flex-shrink-0 mr-2 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path></svg>
                      What does a "lifetime supply" of ByteSize Classes mean?
                  </h3>
                  <p className="text-gray-500">A lifetime supply allows for the ByteSize buddy to take a ByteSize Learning class every week of the whole 10-week summer. They are awarded 10 tickets to redeem for classes each year - we hope they use them all!</p>
              </div>
              <div className="mb-10">
                  <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900">
                      <svg className="flex-shrink-0 mr-2 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path></svg>
                      How can I get involved?
                  </h3>
                  <p className="text-gray-500">There are many ways to get involved with the ByteSize Buddies program! The first step is to reach out to us and let us know you are interested. You can also help by sponsoring a buddy, donating an old laptop or computer, sharing information about the program with others, or generally supporting ByteSize Learning and its mission. We are grateful for all the support we get from wonderful members of the community all over the US!</p>
              </div>
          </div>
      </div>
  </div>
</section>
        </div>
    );
}

export default BuddiesFAQS;
// Ensure static generation
export async function getStaticProps() {
  return {
    props: {},
  };
}

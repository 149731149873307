import React from "react";

import ScratchLogo from "../../assets/icons/scratch-logo.png"
import MinecraftLogo from "../../assets/icons/minecraft-logo.png"
import GIMPLogo from "../../assets/icons/gimp-logo.png"
import VideoLogo from "../../assets/icons/video-logo.png"
import MusicLogo from "../../assets/icons/music-logo.png"
import GameLogo from "../../assets/icons/game-logo.png"
import PythonLogo from "../../assets/icons/python-logo.png"
import JavaLogo from "../../assets/icons/java-logo.png"

//import { Carousel } from "flowbite-react";

import ScratchThumbnail from "../../assets/thumbnails/scratch-thumbnail.jpg";
import MinecraftThumbnail from "../../assets/thumbnails/minecraft-thumbnail.jpg";
import GIMPThumbnail from "../../assets/thumbnails/gimp-thumbnail.jpg";
import VideoThumbnail from "../../assets/thumbnails/video-thumbnail.png";
import MusicThumbnail from "../../assets/thumbnails/music-thumbnail.jpg";
import GameThumbnail from "../../assets/thumbnails/game-thumbnail.png";
import PythonThumbnail from "../../assets/thumbnails/python-thumbnail.png";
import JavaThumbnail from "../../assets/thumbnails/java-thumbnail.png";


function AllClasses() {
    return (
        <div id="AllClasses">
            <section className="bg-white">
                <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                    <div className="mx-auto max-w-screen-sm text-center mb-8 lg:mb-16">
                        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">The ByteSize Bunch of Classes</h2>
                        <p className="font-light text-gray-500 lg:mb-16 sm:text-xl">Explore the range of technical topics we explore with our students on thier journey!</p>
                    </div>
                    <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                        <div className="bg-white rounded-lg border border-gray-200 shadow-sm ">
                            <a href="/programming">
                                <img className="mb-5 rounded-lg" src={ScratchThumbnail} alt="Programming, Game Development and Game Design in Scratch with ByteSize Learning for kids" />
                            </a>
                            <div className="flex items-center mb-3 space-x-2">
                                <img className="w-16 h-12 rounded-lg" src={ScratchLogo} alt="programming and game design in scratch with bytesize learning logo" />
                                <div className="font-medium">
                                    <div>Scratch</div>
                                </div>
                            </div>
                            <h3 className="h-[8%] my-2 mx-2 text-xl font-bold tracking-tight text-gray-900 lg:text-2xl text-center">
                                <a className="text-lg" href="/programming">Game Design in Scratch</a>
                            </h3>
                            <p className="mb-3 font-light text-gray-500 h-auto my-2 mx-2 text-center">Unlock the basics of game design and programming using Scratch in this beginner-friendly technology course!</p>

                            <div className="flex items-center justify-evenly h-16 bottom-0">
                                <a href="/programming" className="inline-flex items-center text-midnight bg-transparent hover:bg-midnight hover:text-white duration-200 border-2 border-midnight focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2   focus:outline-none ">Read Details
                                </a>
                                <a href="/buy" className="inline-flex items-center text-white bg-dodger hover:bg-white hover:text-dodger duration-200 border-2 border-dodger focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2   focus:outline-none ">Buy Now!
                                </a>
                            </div>
                        </div>

                        <div className="bg-white rounded-lg border border-gray-200 shadow-sm ">
                            <a href="/engineering">
                                <img className="mb-5 rounded-lg" src={MinecraftThumbnail} alt="Redstone Engineering in Minecraft with bytesize learning for kids and teens" />
                            </a>
                            <div className="flex items-center mb-3 space-x-2">
                                <img className="w-16 h-12 rounded-lg" src={MinecraftLogo} alt="Redstone Engineering in Minecraft logo with bytesize learning for kids and teens" />
                                <div className="font-medium">
                                    <div>Minecraft</div>
                                </div>
                            </div>
                            <h3 className="h-[8%] my-2 mx-2 text-xl font-bold tracking-tight text-gray-900 lg:text-2xl text-center">
                                <a className="text-lg" href="/engineering">Engineering in Minecraft</a>
                            </h3>
                            <p className="mb-3 font-light text-gray-500 h-auto my-2 mx-2 text-center">Sharpen your skills in engineering by creating Redstone operations in Minecraft, learn the fundamentals of circuits!</p>

                            <div className="flex items-center justify-evenly bottom-0 h-16">
                                <a href="/engineering" className="inline-flex items-center text-midnight bg-transparent hover:bg-midnight hover:text-white duration-200 border-2 border-midnight focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2   focus:outline-none ">Read Details
                                </a>
                                <a href="/buy" className="inline-flex items-center text-white bg-dodger hover:bg-white hover:text-dodger duration-200 border-2 border-dodger focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2   focus:outline-none ">Buy Now!
                                </a>
                            </div>
                        </div>


                        <div className="bg-white rounded-lg border border-gray-200 shadow-sm ">
                            <a href="/programming">
                                <img className="mb-5 rounded-lg" src={PythonThumbnail} alt="Python Coding with bytesize learning for teens" />
                            </a>
                            <div className="flex items-center mb-3 space-x-2">
                                <img className="w-16 h-12 rounded-lg" src={PythonLogo} alt="Python Coding with bytesize learning for teens" />
                                <div className="font-medium">
                                    <div>Python</div>
                                </div>
                            </div>
                            <h3 className="h-[8%] my-2 mx-2 text-xl font-bold tracking-tight text-gray-900 lg:text-2xl text-center">
                                <a className="text-lg" href="/programming">Python Programming</a>
                            </h3>
                            <p className="mb-3 font-light text-gray-500 h-auto my-2 mx-2 text-center">Learn the fundamentals of programming through the use of Python, a powerful and versatile programming language!</p>

                            <div className="flex items-center justify-evenly bottom-0 h-16">
                                <a href="/programming" className="inline-flex items-center text-midnight bg-transparent hover:bg-midnight hover:text-white duration-200 border-2 border-midnight focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2   focus:outline-none ">Read Details
                                </a>
                                <a href="/buy" className="inline-flex items-center text-white bg-dodger hover:bg-white hover:text-dodger duration-200 border-2 border-dodger focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2   focus:outline-none ">Buy Now!
                                </a>
                            </div>
                        </div>

                        <div className="bg-white rounded-lg border border-gray-200 shadow-sm ">
                            <a href="/programming">
                                <img className="mb-5 rounded-lg" src={JavaThumbnail} alt="Java Programming with bytesize learning for teens to learn coding" />
                            </a>
                            <div className="flex items-center mb-3 space-x-2">
                                <img className="w-16 h-12 rounded-lg" src={JavaLogo} alt="Java Programming logo with bytesize learning for teens to learn coding" />
                                <div className="font-medium">
                                    <div>Java</div>
                                </div>
                            </div>
                            <h3 className="h-[8%] my-2 mx-2 text-xl font-bold tracking-tight text-gray-900 lg:text-2xl text-center items-center">
                                <a className="text-lg" href="/programming">Java Programming</a>
                            </h3>
                            <p className="mb-3 font-light text-gray-500 h-auto my-2 mx-2 text-center">Learn one of the most essential and prominent programing languages to date! Make scripts and games to solidify learning!</p>

                            <div className="flex items-center justify-evenly bottom-0 h-16">
                                <a href="/programming" className="inline-flex items-center text-midnight bg-transparent hover:bg-midnight hover:text-white duration-200 border-2 border-midnight focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2   focus:outline-none ">Read Details
                                </a>
                                <a href="/buy" className="inline-flex items-center text-white bg-dodger hover:bg-white hover:text-dodger duration-200 border-2 border-dodger focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2   focus:outline-none ">Buy Now!
                                </a>
                            </div>
                        </div>


                        <div className="bg-white rounded-lg border border-gray-200 shadow-sm ">
                            <a href="/musicproduction">
                                <img className="mb-5 rounded-lg" src={MusicThumbnail} alt="Music Production and Composition in Bandlab with bytesize learning for kids" />
                            </a>
                            <div className="flex items-center mb-3 space-x-2">
                                <img className="w-16 h-12 rounded-lg" src={MusicLogo} alt="Music Production and Composition in Bandlab logo with bytesize learning for kids"/>
                                <div className="font-medium">
                                    <div>Bandlab</div>
                                </div>
                            </div>
                            <h3 className="h-[8%] my-2 mx-2 text-xl font-bold tracking-tight text-gray-900 lg:text-2xl text-center">
                                <a className="text-lg" href="/musicproduction">Music Production in Bandlab</a>
                            </h3>
                            <p className="mb-3 font-light text-gray-500 h-auto my-2 mx-2 text-center">Learn how to create your own music in Bandlab & its parent program Cakewalk. Learn the basics of music production. No keyboard or instrument needed!</p>

                            <div className="flex items-center justify-evenly bottom-0 h-16">
                                <a href="/musicproduction" className="inline-flex items-center text-midnight bg-transparent hover:bg-midnight hover:text-white duration-200 border-2 border-midnight focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2   focus:outline-none ">Read Details
                                </a>
                                <a href="/buy" className="inline-flex items-center text-white bg-dodger hover:bg-white hover:text-dodger duration-200 border-2 border-dodger focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2   focus:outline-none ">Buy Now!
                                </a>
                            </div>
                        </div>

                        <div className="bg-white rounded-lg border border-gray-200 shadow-sm ">
                            <a href="/graphicdesign">
                                <img className="mb-5 rounded-lg" src={GIMPThumbnail} alt="Photography Editing and Graphic Design in GIMP with bytesize learning for children and teens" />
                            </a>
                            <div className="flex items-center mb-3 space-x-2">
                                <img className="w-16 h-12 rounded-lg" src={GIMPLogo} alt="Photography Editing and Graphic Design in GIMP logo with bytesize learning for children and teens" />
                                <div className="font-medium">
                                    <div>GIMP</div>
                                </div>
                            </div>
                            <h3 className="h-[8%] my-2 mx-2 text-xl font-bold tracking-tight text-gray-900 lg:text-2xl text-center">
                                <a className="text-lg" href="/graphicdesign">Graphic Design in GIMP</a>
                            </h3>
                            <p className="mb-3 font-light text-gray-500 h-auto my-2 mx-2 text-center">Learn how to edit your own photographs, create memes, and design awesome logos, thumbnails and more using GIMP!</p>

                            <div className="flex items-center justify-evenly bottom-0 h-16">
                                <a href="/graphicdesign" className="inline-flex items-center text-midnight bg-transparent hover:bg-midnight hover:text-white duration-200 border-2 border-midnight focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2   focus:outline-none ">Read Details
                                </a>
                                <a href="/buy" className="inline-flex items-center text-white bg-dodger hover:bg-white hover:text-dodger duration-200 border-2 border-dodger focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2   focus:outline-none ">Buy Now!
                                </a>
                            </div>
                        </div>

                        <div className="bg-white rounded-lg border border-gray-200 shadow-sm ">
                            <a href="/videoediting">
                                <img className="mb-5 rounded-lg" src={VideoThumbnail} alt="Video Editing and production in Shotcut with bytesize learning for kids" />
                            </a>
                            <div className="flex items-center mb-3 space-x-2">
                                <img className="w-16 h-12 rounded-lg" src={VideoLogo} alt="Video Editing and production in Shotcut logo with bytesize learning for kids" />
                                <div className="font-medium">
                                    <div>Shotcut</div>
                                </div>
                            </div>
                            <h3 className="h-[8%] my-2 mx-2 text-xl font-bold tracking-tight text-gray-900 lg:text-2xl text-center">
                                <a className="text-lg" href="/videoediting">Video Editing in Shotcut</a>
                            </h3>
                            <p className="mb-3 font-light text-gray-500 h-auto my-2 mx-2 text-center">Interested in YouTube or movies? Develop a skill in video editing and production! Create your own video edits, movies, short films and more in Shotcut!</p>

                            <div className="flex items-center justify-evenly bottom-0 h-16">
                                <a href="/videoediting" className="inline-flex items-center text-midnight bg-transparent hover:bg-midnight hover:text-white duration-200 border-2 border-midnight focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2   focus:outline-none ">Read Details
                                </a>
                                <a href="/buy" className="inline-flex items-center text-white bg-dodger hover:bg-white hover:text-dodger duration-200 border-2 border-dodger focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2   focus:outline-none ">Buy Now!
                                </a>
                            </div>
                        </div>

                        <div className="bg-white rounded-lg border border-gray-200 shadow-sm ">
                            <a href="/gamedesign">
                                <img className="mb-5 rounded-lg" src={GameThumbnail} alt="Game Design and game development in Unity with bytesize learning for teens" />
                            </a>
                            <div className="flex items-center mb-3 space-x-2">
                                <img className="w-16 h-12 rounded-lg" src={GameLogo} alt="Game Design and game development in Unity logo with bytesize learning for teens" />
                                <div className="font-medium">
                                    <div>Unity</div>
                                </div>
                            </div>
                            <h3 className="h-[8%] my-2 mx-2 text-xl font-bold tracking-tight text-gray-900 lg:text-2xl text-center">
                                <a className="text-lg" href="/gamedesign">Game Design in Unity</a>
                            </h3>
                            <p className="mb-3 font-light text-gray-500 h-auto my-2 mx-2 text-center">Learn the basics of designing awesome 2D and 3D games from scratch and Unity assets! Game design testing and level development, all in Unity!</p>

                            <div className="flex items-center justify-evenly bottom-0 h-16">
                                <a href="/gamedesign" className="inline-flex items-center text-midnight bg-transparent hover:bg-midnight hover:text-white duration-200 border-2 border-midnight focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2   focus:outline-none ">Read Details
                                </a>
                                <a href="/buy" className="inline-flex items-center text-white bg-dodger hover:bg-white hover:text-dodger duration-200 border-2 border-dodger focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2   focus:outline-none ">Buy Now!
                                </a>
                            </div>
                        </div>

                    </div>
                </div>



            </section>
        </div>
    );
}

export default AllClasses;
// Ensure static generation
export async function getStaticProps() {
  return {
    props: {},
  };
}

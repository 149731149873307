import React from "react";

function Failure() {
    return (
        <section className="bg-white py-40 text-center flex col-span-1 items-center">
            <div className="px-4 mx-auto max-w-screen-xl text-center lg:py-8 lg:px-12 align-middle items-center">
                <div id="alert-additional-content-2" className="p-4 mb-4 text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
                    <div className="items-center py-6">
                        <svg aria-hidden="true" className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                        <span className="sr-only">Info</span>
                        <h3 className="text-lg font-medium">Error</h3>
                    </div>
                    <div className="mt-2 mb-4 text-sm items-center">
                        There was a problem with your purchase. Try again or reach out to us if you continue to experience difficulties.
                        <div className="py-6 mx-auto">
                            <a type="button" href="/contact" className="text-white bg-red-800 hover:bg-red-900 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center   ">
                                Contact Us
                            </a>
                            <a type="button" href="/" className="text-red-800 bg-transparent border border-red-800 hover:bg-red-900 hover:text-white duration-200 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center    " data-dismiss-target="#alert-additional-content-2" aria-label="Close">
                                Dismiss
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Failure;
// Ensure static generation
export async function getStaticProps() {
  return {
    props: {},
  };
}

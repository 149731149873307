import React from "react";
import AboutHero from "./AboutComponents/AboutHero";
import Story from "./AboutComponents/Story";
//import Team from "./AboutComponents/Team";
import Mission from "./AboutComponents/MissionVison";
import FAQ from "./AboutComponents/FAQs";
import NewsletterCTA from "./UniversalComponents/NewsletterCTA"
import OurFounder from "./AboutComponents/OurFounder";

//import 'flowbite/dist/flowbite.min.js';

function About() {
    return (
        <div id="about">
            <AboutHero />
            <Story />
            <OurFounder />
            {/* <Team /> */}
            <Mission />
            <FAQ />
            <NewsletterCTA />
        </div>
    );
}

export default About;
// Ensure static generation
export async function getStaticProps() {
  return {
    props: {},
  };
}

import React from "react";
import BloominChefImage from "../../assets/images/Partnerships/bloominchefimage.jpg";
import BloominChefLogo from "../../assets/images/Partnerships/bloominchef.png";

function BloominChef() {
    return (
        <div id='bloomin_chef'>
            <section className="bg-bloomin-tan">
                <div className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
                    <img className="w-full " src={BloominChefImage} alt="Bloomin' Chef: cooking kits for kids and teens" />
                    <div className="md:mt-0">
                        <div className="max-w-sm mx-auto lg:max-w-none lg:mx-0 mb-8">
                            <img className="object-cover" src={BloominChefLogo} alt="Bloomin' Chef logo: cooking kits for kids and teens" />
                        </div>
                        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">Cooking Kits for Kids</h2>
                        <p className="mb-6 font-light text-gray-500 md:text-lg">Our freinds at Bloomin' Chef grow children's confidence and independance through fun cooking kits, perfect for kids and teens! </p>

                        <span className="inline-flex rounded-md shadow-sm">
                            <p className="mb-6 text-black font-bold md:text-lg">Discount code for 10% off:</p>
                            <p className="mb-6 mx-4 font-bold text-bloomin-green md:text-xl">BYTESIZECHEF10</p>
                        </span>
                        <div className="flex flex-col space-y-4 sm:flex-row sm:space-x-4 sm:space-y-2">
                            <a href="https://www.bloominchef.com/" target="_blank" rel="noopener noreferrer" className="inline-flex items-center border-2 bg-white text-bloomin-green border-bloomin-green hover:bg-bloomin-tan focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                Learn More!
                            </a>
                            <a href="https://www.bloominchef.com/shop" target="_blank" rel="noopener noreferrer" className="inline-flex items-center border-2 border-bloomin-green hover:text-bloomin-green text-white bg-bloomin-green hover:bg-bloomin-tan focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                Buy a Cooking Kit
                                <svg className="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default BloominChef;
// Ensure static generation
export async function getStaticProps() {
  return {
    props: {},
  };
}

import React from "react";
import CheckoutStep1 from "./CheckoutStep1";
import CheckoutStep2 from "./CheckoutStep2";
// import CheckoutStep3 from "./CheckoutStep3";
import CheckoutSummary from "../CheckoutSummary";
import ByteSizeLogo from "../../assets/bytesize-logo.png";
import StudentImage from "../../assets/images/StudentImages/focused-kid.jpg";

class CheckoutPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      step1Data: {},
      step2Data: {},
      step3Data: {},
      error: null,
    };
  }

  handleData = (step, data) => {
    console.log("handleData: ", data);
    this.setState({ [`step${step}Data`]: data, isFormValid: data.isFormValid });
  };

  nextStep = () => {
    this.setState((prevState) => ({ step: prevState.step + 1 }));
  };

  updateFormValidity = (isValid) => {
    this.setState({ isFormValid: isValid });
    console.log("isFormValid: ", isValid);
  };

  renderStep = () => {
    switch (this.state.step) {
      case 1:
        return (
          <CheckoutStep1
            handleData={(data) => this.handleData(1, data)}
            nextStep={this.nextStep}
            updateFormValidity={this.updateFormValidity}
          />
        );
      case 2:
        return (
          <CheckoutStep2
          handleData={(data) => this.handleData(2, data)}
          nextStep={this.nextStep}
          updateFormValidity={this.updateFormValidity}
        />
        );
      case 3:
        return (
          <CheckoutSummary/>
        );
      default:
        return null; // or a 404 page, or a success page, depending on your needs
    }
  };


  render() {
     // eslint-disable-next-line no-unused-vars
    const { step, error } = this.state;

    return (
      <section className="bg-white">
        <div className="lg:flex">
          <div className="hidden w-full max-w-md lg:h-screen lg:block relative">
            <div className="block h-full overflow-hidden">
              <img
                className="object-cover w-full h-full"
                src={StudentImage}
                alt="student"
              />
            </div>
            <div className="absolute inset-0 bg-dodger opacity-20 z-10"></div>
          </div>

          <div className="flex items-center mx-auto md:w-[50rem] px-4 md:px-8 xl:px-0 py-8">
            <div className="w-full">
              <div className="flex items-center justify-center mb-8 space-x-4">
                <a
                  href="/"
                  className="flex items-center text-2xl font-semibold">
                  <img className="w-10 mr-4" src={ByteSizeLogo} alt="logo" />
                  <span className="text-gray-900">ByteSize Learning</span>
                </a>
              </div>
              <ol className="flex items-center text-sm font-medium text-center text-gray-600 mb-4 sm:text-base p-2">
                <li className="flex items-center after:content-[''] after:w-12 after:h-1 after:border-b after:border-dodger after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                  <div
                    className={`flex items-center sm:block after:content-['/'] sm:after:hidden after:mx-2 after:font-light after:text-dodger p-2 rounded-xl p-2 ${
                      this.state.step === 1 ? "text-white bg-dodger" : ""
                    }`}>
                    <div className="sm:mb-2 sm:mx-auto">1</div>
                    Parent & Student Info{" "}
                  </div>
                </li>
                <li className="flex items-center after:content-[''] after:w-12 after:h-1 after:border-b after:border-dodger after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                  <div
                    className={`flex items-center sm:block after:content-['/'] sm:after:hidden after:mx-2 after:font-light after:text-dodger p-2 rounded-xl p-2 ${
                      this.state.step === 2 ? "text-white bg-dodger" : ""
                    }`}>
                    <div className="sm:mb-2 sm:mx-auto">2</div>
                    Class Selection
                  </div>
                </li>
                <li className="flex items-center sm:block">
                  <div
                    className={`mr-2 sm:mb-2 sm:mx-auto p-2 rounded-xl p-2 ${
                      this.state.step === 3 ? "text-white bg-dodger" : ""
                    }`}>
                    <div className="sm:mb-2 sm:mx-auto">3</div>
                    Confirm & Pay
                  </div>
                </li>
              </ol>
              <div className="checkout-step" style={{ minHeight: "500px" }}>
                {this.renderStep()}
              </div>
              {error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}
              <div className="flex space-x-2">
                {step < 3 ? (
                  <></>
                ) : (
                  // <button className="w-full px-5 py-2.5 sm:py-3.5 text-sm font-medium text-center text-white rounded-lg bg-dodger hover:bg-gray-200 hover:text-dodger border-2 border-dodger focus:ring-4 focus:outline-none focus:ring-primary-300 duration-200">
                  //   Submit
                  // </button>
                  <>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CheckoutPage;

// Ensure static generation
export async function getStaticProps() {
  return {
    props: {},
  };
}

import React from "react";
import ChildLearningPicture from "./../../assets/images/StudentImages/child-learning-raised-hand.jpg";

function HeadlineFeature() {
    return (
        <section className="bg-white">
            <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
            <div className="gap-8 items-center lg:grid lg:grid-cols-2 xl:gap-16">
            <div className="text-gray-500 sm:text-lg">
                <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">Learn tech skills of the future - the right way!</h2>
                <p className="mb-8 font-light lg:text-xl">Work with live instructors to build projects in programming, video editing, and more!</p>

                <ul className="pt-8 my-7 space-y-5 border-t border-gray-300 ">
                    <li className="flex space-x-3">

                        <svg className="flex-shrink-0 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                        <span className="text-base font-medium leading-tight text-gray-500">Live, virtual courses</span>
                    </li>
                    <li className="flex space-x-3">

                        <svg className="flex-shrink-0 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                        <span className="text-base font-medium leading-tight text-gray-500">Project-based and student-driven</span>
                    </li>
                    <li className="flex space-x-3">

                        <svg className="flex-shrink-0 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                        <span className="text-base font-medium leading-tight text-gray-500">Fun, useful technology skills</span>
                    </li>
                </ul>
                <p className="mb-8 font-light lg:text-xl">Put your child's screen time to better use, starting with a ByteSize Learning course. Register today!</p>
            </div>
            <img className="hidden mb-4 w-full lg:mb-0 lg:flex rounded-lg" src={ ChildLearningPicture } alt="child learning technology skills online with bytesize learning" />
        </div>
        </div>
    </section>

    );
}

export default HeadlineFeature;

// Ensure static generation
export async function getStaticProps() {
  return {
    props: {},
  };
}

import React, { useEffect, useRef } from "react";

import ScratchLogo from "../assets/icons/scratch-logo.png";
import MinecraftLogo from "../assets/icons/minecraft-logo.png";
import GIMPLogo from "../assets/icons/gimp-logo.png";
import VideoLogo from "../assets/icons/video-logo.png";
import MusicLogo from "../assets/icons/music-logo.png";
import GameLogo from "../assets/icons/game-logo.png";
import PythonLogo from "../assets/icons/python-logo.png";
import JavaLogo from "../assets/icons/java-logo.png";

import ScratchThumbnail from "../assets/thumbnails/scratch-thumbnail.jpg";
import MinecraftThumbnail from "../assets/thumbnails/minecraft-thumbnail.jpg";
import GIMPThumbnail from "../assets/thumbnails/gimp-thumbnail.jpg";
import VideoThumbnail from "../assets/thumbnails/video-thumbnail.png";
import MusicThumbnail from "../assets/thumbnails/music-thumbnail.jpg";
import GameThumbnail from "../assets/thumbnails/game-thumbnail.png";
import PythonThumbnail from "../assets/thumbnails/python-thumbnail.png";
import JavaThumbnail from "../assets/thumbnails/java-thumbnail.png";

const tabs = [
  {
    tab: "unitbyte-tab",
    content: "unitbyte-content",
  },
  {
    tab: "kilobyte-tab",
    content: "kilobyte-content",
  },
  {
    tab: "megabyte-tab",
    content: "megabyte-content",
  },
  {
    tab: "gigabyte-tab",
    content: "gigabyte-content",
  },
];

const Purchase = () => {
  const activeTabRef = useRef(tabs[0].tab);

  useEffect(() => {
    const handleTabClick = (tab, content) => {
      tabs.forEach((t) => {
        document.getElementById(t.content).classList.add("hidden");
        document.getElementById(t.tab).classList.remove(
          "text-gray-900",
          "bg-gray-100",
          "bg-white",
          "hover:text-gray-700",
          "hover:bg-gray-50"
        );
      });

      document.getElementById(tab).classList.add("text-gray-900", "bg-gray-100");
      document.getElementById(content).classList.remove("hidden");

      activeTabRef.current = tab;
    };

    tabs.forEach((t) => {
      document
        .getElementById(t.tab)
        .addEventListener("click", () => handleTabClick(t.tab, t.content));
      if (t.tab !== activeTabRef.current) {
        document.getElementById(t.tab).classList.add(
          "bg-white",
          "hover:text-gray-700",
          "hover:bg-gray-50"
        );
      }
    });

    document.getElementById(activeTabRef.current).click();
  }, []);


  return (
    <div id="purchase">
      <section className="bg-white">
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
          <div className="bg-white rounded-lg divide-y divide-gray-200 shadow  lg:divide-y-0 lg:divide-x">
            <div className="text-center mt-16 md:mt-20">
              <ul
                className="grid grid-cols-2 text-sm font-medium text-center text-gray-500 shadow md:rounded-lg md:grid-cols-4 "
                role="tablist"
              >
                <li className="w-full" role="presentation">
                  <button
                    id="unitbyte-tab"
                    type="button"
                    className="inline-block p-4 w-full border border-gray-200  md:rounded-l-lg"
                  >
                    Single Byte
                  </button>
                </li>
                <li className="w-full" role="presentation">
                  <button
                    id="kilobyte-tab"
                    type="button"
                    className="inline-block p-4 w-full border  border-gray-200"
                  >
                    KiloByte
                  </button>
                </li>
                <li className="w-full" role="presentation">
                  <button
                    id="megabyte-tab"
                    type="button"
                    className="inline-block p-4 w-full border  border-gray-200"
                  >
                    MegaByte
                  </button>
                </li>
                <li className="w-full" role="presentation">
                  <button
                    id="gigabyte-tab"
                    type="button"
                    className="inline-block p-4 w-full border border-gray-200  md:rounded-r-lg"
                  >
                    GigaByte
                  </button>
                </li>
              </ul>
              <div id="unitbyte-content" className="hidden">
                <div className="mx-auto max-w-screen-md text-center my-8 lg:mb-12">
                  <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">
                    ByteSize Single Byte Plan         
                  </h2>
                  <span className="mr-2 text-5xl font-extrabold">$249</span>
                      <span className="text-gray-500">
                        /class - 1 week
                      </span>
                </div>

                <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                  <div className="bg-white rounded-lg border border-gray-200 shadow-sm ">
                    <a href="/programming">
                      <img className="mb-5 rounded-lg" src={ScratchThumbnail} alt="Programming and Game Design in Scratch with ByteSize Learning for kids" />
                    </a>
                    <div className="flex items-center mb-3 space-x-2">
                      <img className="w-16 h-12 rounded-lg" src={ScratchLogo} alt="Programming and Game Design in Scratch Logo with ByteSize Learning for kids" />
                      <div className="font-medium">
                        <div>Scratch</div>
                      </div>
                    </div>
                    <h3 className="h-[8%] my-2 mx-2 text-xl font-bold tracking-tight text-gray-900 lg:text-2xl text-center">
                      <a className="text-lg" href="/programming">Game Design in Scratch</a>
                    </h3>
                    <p className="mb-3 font-light text-gray-500 h-auto my-2 mx-2 text-center">Unlock the basics of game design and programming using Scratch in this beginner-friendly technology course!</p>

                    <div className="flex items-center justify-evenly h-16 bottom-0">
                      <a href="/programming" className="inline-flex items-center text-midnight bg-transparent hover:bg-midnight hover:text-white duration-200 border-2 border-midnight focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2   focus:outline-none ">Read Details
                      </a>
                      <a href="/checkout" className="inline-flex items-center text-white bg-dodger hover:bg-white hover:text-dodger duration-200 border-2 border-dodger focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2   focus:outline-none ">Buy Now!
                      </a>
                    </div>
                  </div>

                  <div className="bg-white rounded-lg border border-gray-200 shadow-sm ">
                    <a href="/engineering">
                      <img className="mb-5 rounded-lg" src={MinecraftThumbnail} alt="Redstone Engineering in Minecraft with ByteSize Learning for kids" />
                    </a>
                    <div className="flex items-center mb-3 space-x-2">
                      <img className="w-16 h-12 rounded-lg" src={MinecraftLogo} alt="Redstone Engineering in Minecraft Logo with ByteSize Learning for kids" />
                      <div className="font-medium">
                        <div>Minecraft</div>
                      </div>
                    </div>
                    <h3 className="h-[8%] my-2 mx-2 text-xl font-bold tracking-tight text-gray-900 lg:text-2xl text-center">
                      <a className="text-lg" href="/engineering">Engineering in Minecraft</a>
                    </h3>
                    <p className="mb-3 font-light text-gray-500 h-auto my-2 mx-2 text-center">Sharpen your skills in engineering by creating Redstone operations in Minecraft, learn the fundamentals of circuits!</p>

                    <div className="flex items-center justify-evenly bottom-0 h-16">
                      <a href="/engineering" className="inline-flex items-center text-midnight bg-transparent hover:bg-midnight hover:text-white duration-200 border-2 border-midnight focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2   focus:outline-none ">Read Details
                      </a>
                      <a href="/checkout" className="inline-flex items-center text-white bg-dodger hover:bg-white hover:text-dodger duration-200 border-2 border-dodger focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2   focus:outline-none ">Buy Now!
                      </a>
                    </div>
                  </div>


                  <div className="bg-white rounded-lg border border-gray-200 shadow-sm ">
                    <a href="/programming">
                      <img className="mb-5 rounded-lg" src={PythonThumbnail} alt="Python Programming with ByteSize Learning for kids" />
                    </a>
                    <div className="flex items-center mb-3 space-x-2">
                      <img className="w-16 h-12 rounded-lg" src={PythonLogo} alt="Python Programming Logo with ByteSize Learning for kids" />
                      <div className="font-medium">
                        <div>Python</div>
                      </div>
                    </div>
                    <h3 className="h-[8%] my-2 mx-2 text-xl font-bold tracking-tight text-gray-900 lg:text-2xl text-center">
                      <a className="text-lg" href="/programming">Python Programming</a>
                    </h3>
                    <p className="mb-3 font-light text-gray-500 h-auto my-2 mx-2 text-center">Learn the fundamentals of programming through the use of Python, a powerful and versatile programming language!</p>

                    <div className="flex items-center justify-evenly bottom-0 h-16">
                      <a href="/programming" className="inline-flex items-center text-midnight bg-transparent hover:bg-midnight hover:text-white duration-200 border-2 border-midnight focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2   focus:outline-none ">Read Details
                      </a>
                      <a href="/checkout" className="inline-flex items-center text-white bg-dodger hover:bg-white hover:text-dodger duration-200 border-2 border-dodger focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2   focus:outline-none ">Buy Now!
                      </a>
                    </div>
                  </div>

                  <div className="bg-white rounded-lg border border-gray-200 shadow-sm ">
                    <a href="/programming">
                      <img className="mb-5 rounded-lg" src={JavaThumbnail} alt="Java Programming with ByteSize Learning for kids" />
                    </a>
                    <div className="flex items-center mb-3 space-x-2">
                      <img className="w-16 h-12 rounded-lg" src={JavaLogo} alt="Java Programming Logo with ByteSize Learning for kids" />
                      <div className="font-medium">
                        <div>Java</div>
                      </div>
                    </div>
                    <h3 className="h-[8%] my-2 mx-2 text-xl font-bold tracking-tight text-gray-900 lg:text-2xl text-center items-center">
                      <a className="text-lg" href="/programming">Java Programming</a>
                    </h3>
                    <p className="mb-3 font-light text-gray-500 h-auto my-2 mx-2 text-center">Learn one of the most essential and prominent programing languages to date! Make scripts and games to solidify learning!</p>

                    <div className="flex items-center justify-evenly bottom-0 h-16">
                      <a href="/programming" className="inline-flex items-center text-midnight bg-transparent hover:bg-midnight hover:text-white duration-200 border-2 border-midnight focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2   focus:outline-none ">Read Details
                      </a>
                      <a href="/checkout" className="inline-flex items-center text-white bg-dodger hover:bg-white hover:text-dodger duration-200 border-2 border-dodger focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2   focus:outline-none ">Buy Now!
                      </a>
                    </div>
                  </div>


                  <div className="bg-white rounded-lg border border-gray-200 shadow-sm ">
                    <a href="/musicproduction">
                      <img className="mb-5 rounded-lg" src={MusicThumbnail} alt="Music Production and Composition in Bandlab with ByteSize Learning for kids" />
                    </a>
                    <div className="flex items-center mb-3 space-x-2">
                      <img className="w-16 h-12 rounded-lg" src={MusicLogo} alt="Music Production and Composition in Bandlab logo with ByteSize Learning for kids" />
                      <div className="font-medium">
                        <div>Bandlab</div>
                      </div>
                    </div>
                    <h3 className="h-[8%] my-2 mx-2 text-xl font-bold tracking-tight text-gray-900 lg:text-2xl text-center">
                      <a className="text-lg" href="/musicproduction">Music Production in Bandlab</a>
                    </h3>
                    <p className="mb-3 font-light text-gray-500 h-auto my-2 mx-2 text-center">Learn how to create your own music in Bandlab & its parent program Cakewalk. Learn the basics of music production. No keyboard or instrument needed!</p>

                    <div className="flex items-center justify-evenly bottom-0 h-16">
                      <a href="/musicproduction" className="inline-flex items-center text-midnight bg-transparent hover:bg-midnight hover:text-white duration-200 border-2 border-midnight focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2   focus:outline-none ">Read Details
                      </a>
                      <a href="/checkout" className="inline-flex items-center text-white bg-dodger hover:bg-white hover:text-dodger duration-200 border-2 border-dodger focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2   focus:outline-none ">Buy Now!
                      </a>
                    </div>
                  </div>

                  <div className="bg-white rounded-lg border border-gray-200 shadow-sm ">
                    <a href="/graphicdesign">
                      <img className="mb-5 rounded-lg" src={GIMPThumbnail} alt="Photography Editing and Graphic Design in GIMP with ByteSize Learning for kids" />
                    </a>
                    <div className="flex items-center mb-3 space-x-2">
                      <img className="w-16 h-12 rounded-lg" src={GIMPLogo} alt="Photography Editing and Graphic Design in GIMP logo with ByteSize Learning for kids" />
                      <div className="font-medium">
                        <div>GIMP</div>
                      </div>
                    </div>
                    <h3 className="h-[8%] my-2 mx-2 text-xl font-bold tracking-tight text-gray-900 lg:text-2xl text-center">
                      <a className="text-lg" href="/graphicdesign">Graphic Design in GIMP</a>
                    </h3>
                    <p className="mb-3 font-light text-gray-500 h-auto my-2 mx-2 text-center">Learn how to edit your own photographs, create memes, and design awesome logos, thumbnails and more using GIMP!</p>

                    <div className="flex items-center justify-evenly bottom-0 h-16">
                      <a href="/graphicdesign" className="inline-flex items-center text-midnight bg-transparent hover:bg-midnight hover:text-white duration-200 border-2 border-midnight focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2   focus:outline-none ">Read Details
                      </a>
                      <a href="/checkout" className="inline-flex items-center text-white bg-dodger hover:bg-white hover:text-dodger duration-200 border-2 border-dodger focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2   focus:outline-none ">Buy Now!
                      </a>
                    </div>
                  </div>

                  <div className="bg-white rounded-lg border border-gray-200 shadow-sm ">
                    <a href="/videoediting">
                      <img className="mb-5 rounded-lg" src={VideoThumbnail} alt="Video Editing and Production in Shotcut with ByteSize Learning for kids" />
                    </a>
                    <div className="flex items-center mb-3 space-x-2">
                      <img className="w-16 h-12 rounded-lg" src={VideoLogo} alt="Video Editing and Production in Shotcut logo with ByteSize Learning for kids" />
                      <div className="font-medium">
                        <div>Shotcut</div>
                      </div>
                    </div>
                    <h3 className="h-[8%] my-2 mx-2 text-xl font-bold tracking-tight text-gray-900 lg:text-2xl text-center">
                      <a className="text-lg" href="/videoediting">Video Editing in Shotcut</a>
                    </h3>
                    <p className="mb-3 font-light text-gray-500 h-auto my-2 mx-2 text-center">Interested in YouTube or movies? Develop a skill in video editing and production! Create your own video edits, movies, short films and more in Shotcut!</p>

                    <div className="flex items-center justify-evenly bottom-0 h-16">
                      <a href="/videoediting" className="inline-flex items-center text-midnight bg-transparent hover:bg-midnight hover:text-white duration-200 border-2 border-midnight focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2   focus:outline-none ">Read Details
                      </a>
                      <a href="/checkout" className="inline-flex items-center text-white bg-dodger hover:bg-white hover:text-dodger duration-200 border-2 border-dodger focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2   focus:outline-none ">Buy Now!
                      </a>
                    </div>
                  </div>

                  <div className="bg-white rounded-lg border border-gray-200 shadow-sm ">
                    <a href="/gamedesign">
                      <img className="mb-5 rounded-lg" src={GameThumbnail} alt="Game Design and Game Development in Unity with ByteSize Learning for kids" />
                    </a>
                    <div className="flex items-center mb-3 space-x-2">
                      <img className="w-16 h-12 rounded-lg" src={GameLogo} alt="Game Design and Game Development in Unity logo with ByteSize Learning for kids" />
                      <div className="font-medium">
                        <div>Unity</div>
                      </div>
                    </div>
                    <h3 className="h-[8%] my-2 mx-2 text-xl font-bold tracking-tight text-gray-900 lg:text-2xl text-center">
                      <a className="text-lg" href="/gamedesign">Game Design in Unity</a>
                    </h3>
                    <p className="mb-3 font-light text-gray-500 h-auto my-2 mx-2 text-center">Learn the basics of designing awesome 2D and 3D games from scratch and Unity assets! Game design testing and level development, all in Unity!</p>

                    <div className="flex items-center justify-evenly bottom-0 h-16">
                      <a href="/gamedesign" className="inline-flex items-center text-midnight bg-transparent hover:bg-midnight hover:text-white duration-200 border-2 border-midnight focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2   focus:outline-none ">Read Details
                      </a>
                      <a href="/checkout" className="inline-flex items-center text-white bg-dodger hover:bg-white hover:text-dodger duration-200 border-2 border-dodger focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 lg:px-5 lg:py-2.5 mr-2   focus:outline-none ">Buy Now!
                      </a>
                    </div>
                  </div>

                </div>

              </div>
              <div id="kilobyte-content" className="hidden">
                <div className="mx-auto max-w-screen-md text-center my-8 lg:mb-12">
                  <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">
                    ByteSize KiloByte Plan
                  </h2>
                  <p className="font-light text-gray-500 sm:text-xl">
                    Become a ByteSize KiloByte student and enroll in two of our classes that best fit your interests! No matter what your interests are, we have a plan for you! No prior knowledge is required.
                  </p>
                </div>

                <div className="space-y-8 px-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0 space-evenly">


                  {/* YouTuber */}
                  <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow  xl:p-8">
                    <h3 className="mb-4 text-2xl font-semibold">
                      YouTuber
                    </h3>
                    <p className="font-light text-gray-500 sm:text-lg">
                      Great for those future YouTubers out there!
                    </p>
                    <div className="flex justify-center items-baseline my-8">
                      <span className="mr-2 text-5xl font-extrabold">$475</span>
                      <span className="text-gray-500">
                        /2 classes
                      </span>
                    </div>
                    <ul className="mb-8 space-y-4 text-left">
                      <li className="flex items-center space-x-3">
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-green-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>Video Editing in Shotcut</span>
                      </li>
                      <li className="flex items-center space-x-3">
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-green-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>Graphic Design in GIMP</span>
                      </li>
                    </ul>
                    <a
                      href="/checkout"
                      className="text-white bg-dodger hover:bg-dodger focus:ring-4 focus:ring-dodger font-medium rounded-lg text-sm px-5 py-2.5 text-center  "
                    >
                      Purchase
                    </a>
                  </div>

                  {/* Music Producer */}
                  <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow  xl:p-8">
                    <h3 className="mb-4 text-2xl font-semibold">
                      Music Producer
                    </h3>
                    <p className="font-light text-gray-500 sm:text-lg">
                      Perfect for kids who love music and watching videos or movies!
                    </p>
                    <div className="flex justify-center items-baseline my-8">
                      <span className="mr-2 text-5xl font-extrabold">$475</span>
                      <span className="text-gray-500">
                        /2 classes
                      </span>
                    </div>
                    <ul className="mb-8 space-y-4 text-left">
                      <li className="flex items-center space-x-3">
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-green-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>Video Editing in Shotcut</span>
                      </li>
                      <li className="flex items-center space-x-3">
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-green-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>Music Production in Bandlab</span>
                      </li>
                    </ul>
                    <a
                      href="/checkout"
                      className="text-white bg-dodger hover:bg-dodger focus:ring-4 focus:ring-dodger font-medium rounded-lg text-sm px-5 py-2.5 text-center  "
                    >
                      Purchase
                    </a>
                  </div>

                  {/* Junior Game Designer */}
                  <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow  xl:p-8">
                    <h3 className="mb-4 text-2xl font-semibold">
                      Junior Game Designer
                    </h3>
                    <p className="font-light text-gray-500 sm:text-lg">
                      Great for those young video game lovers and Minecrafters!
                    </p>
                    <div className="flex justify-center items-baseline my-8">
                      <span className="mr-2 text-5xl font-extrabold">$475</span>
                      <span className="text-gray-500">
                        /2 classes
                      </span>
                    </div>
                    <ul className="mb-8 space-y-4 text-left">
                      <li className="flex items-center space-x-3">
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-green-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>Engineering in Minecraft</span>
                      </li>
                      <li className="flex items-center space-x-3">
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-green-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>Game Design in Scratch</span>
                      </li>
                    </ul>
                    <a
                      href="/checkout"
                      className="text-white bg-dodger hover:bg-dodger focus:ring-4 focus:ring-dodger font-medium rounded-lg text-sm px-5 py-2.5 text-center  "
                    >
                      Purchase
                    </a>
                  </div>

                  {/* Senior Game Designer */}
                  <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow  xl:p-8">
                    <h3 className="mb-4 text-2xl font-semibold">
                      Senior Game Designer
                    </h3>
                    <p className="font-light text-gray-500 sm:text-lg">
                      Hardcore gamer in the house? Put that time to good use!
                    </p>
                    <div className="flex justify-center items-baseline my-8">
                      <span className="mr-2 text-5xl font-extrabold">$475</span>
                      <span className="text-gray-500">
                        /2 classes
                      </span>
                    </div>
                    <ul className="mb-8 space-y-4 text-left">
                      <li className="flex items-center space-x-3">
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-green-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>Game Design in Unity</span>
                      </li>
                      <li className="flex items-center space-x-3">
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-green-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>Programming in Java</span>
                      </li>
                    </ul>
                    <a
                      href="/checkout"
                      className="text-white bg-dodger hover:bg-dodger focus:ring-4 focus:ring-dodger font-medium rounded-lg text-sm px-5 py-2.5 text-center  "
                    >
                      Purchase
                    </a>
                  </div>

                  {/* Junior Engineer */}
                  <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow  xl:p-8">
                    <h3 className="mb-4 text-2xl font-semibold">
                      Junior Engineer
                    </h3>
                    <p className="font-light text-gray-500 sm:text-lg">
                      Perfect for the Lego lovers and budding engineers!
                    </p>
                    <div className="flex justify-center items-baseline my-8">
                      <span className="mr-2 text-5xl font-extrabold">$475</span>
                      <span className="text-gray-500">
                        /2 classes
                      </span>
                    </div>
                    <ul className="mb-8 space-y-4 text-left">
                      <li className="flex items-center space-x-3">
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-green-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>Engineering in Minecraft</span>
                      </li>
                      <li className="flex items-center space-x-3">
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-green-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>Programming in Scratch</span>
                      </li>
                    </ul>
                    <a
                      href="/checkout"
                      className="text-white bg-dodger hover:bg-dodger focus:ring-4 focus:ring-dodger font-medium rounded-lg text-sm px-5 py-2.5 text-center  "
                    >
                      Purchase
                    </a>
                  </div>

                  {/* Senior Engineer*/}
                  <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow  xl:p-8">
                    <h3 className="mb-4 text-2xl font-semibold">
                      Senior Engineer
                    </h3>
                    <p className="font-light text-gray-500 sm:text-lg">
                      Great for someone interested in a career in programming!
                    </p>
                    <div className="flex justify-center items-baseline my-8">
                      <span className="mr-2 text-5xl font-extrabold">$475</span>
                      <span className="text-gray-500">
                        /2 classes
                      </span>
                    </div>
                    <ul className="mb-8 space-y-4 text-left">
                      <li className="flex items-center space-x-3">
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-green-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>Programming in Python</span>
                      </li>
                      <li className="flex items-center space-x-3">
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-green-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>Programming in Java</span>
                      </li>
                    </ul>
                    <a
                      href="/checkout"
                      className="text-white bg-dodger hover:bg-dodger focus:ring-4 focus:ring-dodger font-medium rounded-lg text-sm px-5 py-2.5 text-center  "
                    >
                      Purchase
                    </a>
                  </div>

                  {/* Tech Artist*/}
                  <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow  xl:p-8">
                    <h3 className="mb-4 text-2xl font-semibold">
                      Techie Artist
                    </h3>
                    <p className="font-light text-gray-500 sm:text-lg">
                      Convert paper and pencil into digital art and animations!
                    </p>
                    <div className="flex justify-center items-baseline my-8">
                      <span className="mr-2 text-5xl font-extrabold">$475</span>
                      <span className="text-gray-500">
                        /2 classes
                      </span>
                    </div>
                    <ul className="mb-8 space-y-4 text-left">
                      <li className="flex items-center space-x-3">
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-green-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>Graphic Design in GIMP</span>
                      </li>
                      <li className="flex items-center space-x-3">
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-green-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>Programming in Scratch</span>
                      </li>
                    </ul>
                    <a
                      href="/checkout"
                      className="text-white bg-dodger hover:bg-dodger focus:ring-4 focus:ring-dodger font-medium rounded-lg text-sm px-5 py-2.5 text-center  "
                    >
                      Purchase
                    </a>
                  </div>

                  {/* Minecraft YouTuber */}
                  <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow  xl:p-8">
                    <h3 className="mb-4 text-2xl font-semibold">
                      Minecraft YouTuber
                    </h3>
                    <p className="font-light text-gray-500 sm:text-lg">
                      Minecraft + video editing = guaranteed for tons of fun!
                    </p>
                    <div className="flex justify-center items-baseline my-8">
                      <span className="mr-2 text-5xl font-extrabold">$475</span>
                      <span className="text-gray-500">
                        /2 classes
                      </span>
                    </div>
                    <ul className="mb-8 space-y-4 text-left">
                      <li className="flex items-center space-x-3">
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-green-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>Video Editing in Shotcut</span>
                      </li>
                      <li className="flex items-center space-x-3">
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-green-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>Engineering in Minecraft</span>
                      </li>
                    </ul>
                    <a
                      href="/checkout"
                      className="text-white bg-dodger hover:bg-dodger focus:ring-4 focus:ring-dodger font-medium rounded-lg text-sm px-5 py-2.5 text-center  "
                    >
                      Purchase
                    </a>
                  </div>

                  {/* Custom */}
                  <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow  xl:p-8">
                    <h3 className="mb-4 text-2xl font-semibold">
                      Custom
                    </h3>
                    <p className="font-light text-gray-500 sm:text-lg">
                      Choose any two classes and we'll make it happen!
                    </p>
                    <div className="flex justify-center items-baseline my-8">
                      <span className="mr-2 text-5xl font-extrabold">$475</span>
                      <span className="text-gray-500">
                        /2 classes
                      </span>
                    </div>
                    <ul className="mb-8 space-y-4 text-left">
                      <li className="flex items-center space-x-3">
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-green-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>Combine two tech interests</span>
                      </li>
                      <li className="flex items-center space-x-3">
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-green-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>Develop a unique skillset</span>
                      </li>
                    </ul>
                    <a
                      href="/checkout"
                      className="text-white bg-dodger hover:bg-dodger focus:ring-4 focus:ring-dodger font-medium rounded-lg text-sm px-5 py-2.5 text-center  "
                    >
                      Purchase
                    </a>
                  </div>


                </div>
              </div>
              <div id="megabyte-content" className="hidden">

                {/* MEGABYTE PLAN */}
                <div className="mx-auto max-w-screen-md text-center my-8 lg:mb-12">
                  <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">
                    ByteSize MegaByte Plan
                  </h2>
                  <p className="mb-5 font-light text-gray-500 sm:text-xl">
                    Become a MegaByte student and enroll in four ByteSize
                    TechCamp classes. No matter what your interests are, we have
                    a class for you! No prior knowledge is required.
                  </p>
                </div>
                <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0 space-evenly">
                  <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow  xl:p-8">
                    <h3 className="mb-4 text-2xl font-semibold">
                      Gaming & Coding
                    </h3>
                    <p className="font-light text-gray-500 sm:text-lg">
                      Great for gamers, future programmers, and those who want
                      to learn how to create their own games.
                    </p>
                    <div className="flex justify-center items-baseline my-8">
                      <span className="mr-2 text-5xl font-extrabold">$899</span>
                      <span className="text-gray-500">
                        /4 classes
                      </span>
                    </div>

                    <ul className="mb-8 space-y-4 text-left">
                      <li className="flex items-center space-x-3">
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-green-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>Game Design in Scratch</span>
                      </li>
                      <li className="flex items-center space-x-3">
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-green-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>Game Design in Unity</span>
                      </li>
                      <li className="flex items-center space-x-3">
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-green-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>Programming in Java</span>
                      </li>
                      <li className="flex items-center space-x-3">
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-green-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>Programming in Python</span>
                      </li>
                    </ul>
                    <a
                      href="/checkout"
                      className="text-white bg-dodger hover:bg-dodger focus:ring-4 focus:ring-dodger font-medium rounded-lg text-sm px-5 py-2.5 text-center  "
                    >
                      Purchase
                    </a>
                  </div>

                  <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow  xl:p-8">
                    <h3 className="mb-4 text-2xl font-semibold">Creative Suite</h3>
                    <p className="font-light text-gray-500 sm:text-lg">
                      Great for kids to learn awesome creative skills to bring
                      their imagination to life on screen - video, music & more!
                    </p>
                    <div className="flex justify-center items-baseline my-8">
                      <span className="mr-2 text-5xl font-extrabold">$899</span>
                      <span className="text-gray-500">
                        /4 classes
                      </span>
                    </div>

                    <ul className="mb-8 space-y-4 text-left">
                      <li className="flex items-center space-x-3">
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-green-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>Video Editing in Shotcut</span>
                      </li>
                      <li className="flex items-center space-x-3">
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-green-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>Music Production in Bandlab</span>
                      </li>
                      <li className="flex items-center space-x-3">
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-green-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>Graphic Design in GIMP</span>
                      </li>
                      <li className="flex items-center space-x-3">
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-green-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>Game Design in Scratch</span>
                      </li>
                    </ul>
                    <a
                      href="/checkout"
                      className="text-white bg-dodger hover:bg-dodger focus:ring-4 focus:ring-dodger font-medium rounded-lg text-sm px-5 py-2.5 text-center  "
                    >
                      Purchase
                    </a>
                  </div>

                  <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow  xl:p-8">
                    <h3 className="mb-4 text-2xl font-semibold">Custom</h3>
                    <p className="font-light text-gray-500 sm:text-lg">
                      Choose any four classes that interests your kid and give
                      them a useful set of skills in a wide range of topics.
                    </p>
                    <div className="flex justify-center items-baseline my-8">
                      <span className="mr-2 text-5xl font-extrabold">$899</span>
                      <span className="text-gray-500">
                        /4 classes
                      </span>
                    </div>

                    <ul className="mb-8 space-y-4 text-left">
                      <li className="flex items-center space-x-3">
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-green-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>Choose any four of our classes</span>
                      </li>
                      <li className="flex items-center space-x-3">
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-green-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>No prior experience required</span>
                      </li>
                      <li className="flex items-center space-x-3">
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-green-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>Diversify your kid's skillset</span>
                      </li>
                      <li className="flex items-center space-x-3">
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-green-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>Cater to a wide range of interests</span>
                      </li>
                    </ul>
                    <a
                      href="/checkout"
                      className="text-white bg-dodger hover:bg-dodger focus:ring-4 focus:ring-dodger font-medium rounded-lg text-sm px-5 py-2.5 text-center  "
                    >
                      Purchase
                    </a>
                  </div>
                </div>
              </div>
              <div id="gigabyte-content" className="hidden">
                <div className="mx-auto max-w-screen-md text-center my-8 lg:mb-12">
                  <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">
                    ByteSize GigaByte Plan
                  </h2>
                  <p className="font-light text-gray-500 sm:text-xl">
                    Become a ByteSize GigaByte student and enroll in all eight ByteSize
                    classes. No matter what your interests are, you will learn it all! No prior knowledge is required.
                  </p>
                </div>
                <div className="space-y-8 px-4 mx-auto max-w-screen-xl">
                  <div className="bg-white rounded-lg shadow lg:grid lg:grid-cols-3">
                    <div className="col-span-2 p-6 lg:p-8">
                      <h2 className="mb-1 text-2xl font-bold text-gray-900">
                        All of our classes, all the skills, everything right here
                      </h2>
                      <p className="text-lg font-light text-gray-500">
                        Best for young learners who want to learn everything ByteSize has to offer.
                      </p>
                      <div className="grid gap-4 mt-4 lg:mt-6 sm:grid-cols-2 md:grid-cols-3">
                        <ul className="space-y-4">
                          <li className="flex space-x-2.5">
                            <svg
                              className="flex-shrink-0 w-5 h-5 text-green-500"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                            <span className="leading-tight text-gray-500">
                              Programming in Python
                            </span>
                          </li>
                          <li className="flex space-x-2.5">
                            <svg
                              className="flex-shrink-0 w-5 h-5 text-green-500"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                            <span className="leading-tight text-gray-500">
                              Programming in Java
                            </span>
                          </li>
                          <li className="flex space-x-2.5">
                            <svg
                              className="flex-shrink-0 w-5 h-5 text-green-500"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                            <span className="leading-tight text-gray-500">
                              Game Design in Scratch
                            </span>
                          </li>
                          <li className="flex space-x-2.5">
                            <svg
                              className="flex-shrink-0 w-5 h-5 text-green-500"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                            <span className="leading-tight text-gray-500">
                              Game Design in Unity
                            </span>
                          </li>
                          <li className="flex space-x-2.5">
                            <svg
                              className="flex-shrink-0 w-5 h-5 text-green-500"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                            <span className="leading-tight text-gray-500">
                              Video Editing in Shotcut
                            </span>
                          </li>
                        </ul>
                        <ul

                          className="space-y-4 hidden sm:block"
                        >
                          <li className="flex space-x-2.5">
                            <svg
                              className="flex-shrink-0 w-5 h-5 text-green-500"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                            <span className="leading-tight text-gray-500">
                              Music Production in Bandlab
                            </span>
                          </li>
                          <li className="flex space-x-2.5">
                            <svg
                              className="flex-shrink-0 w-5 h-5 text-green-500"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                            <span className="leading-tight text-gray-500">
                              Graphic Design in GIMP
                            </span>
                          </li>
                          <li className="flex space-x-2.5">
                            <svg
                              className="flex-shrink-0 w-5 h-5 text-green-500"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                            <span className="leading-tight text-gray-500">
                              Redstone Engineering in Minecraft
                            </span>
                          </li>
                          <li className="flex space-x-2.5">
                            <svg
                              className="flex-shrink-0 w-5 h-5 text-green-500"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                            <span className="leading-tight text-gray-500">
                              All the basics of coding
                            </span>
                          </li>
                          <li className="flex space-x-2.5">
                            <svg
                              className="flex-shrink-0 w-5 h-5 text-green-500"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                            <span className="leading-tight text-gray-500">
                              The principles of game design
                            </span>
                          </li>
                        </ul>
                        <ul

                          className="space-y-4 hidden lg:block"
                        >
                          <li className="flex space-x-2.5">
                            <svg
                              className="flex-shrink-0 w-5 h-5 text-green-500"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                            <span className="leading-tight text-gray-500">
                              Basic Video, and photo editing
                            </span>
                          </li>
                          <li className="flex space-x-2.5">
                            <svg
                              className="flex-shrink-0 w-5 h-5 text-green-500"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                            <span className="leading-tight text-gray-500">
                              Music and audio editing
                            </span>
                          </li>
                          <li className="flex space-x-2.5">
                            <svg
                              className="flex-shrink-0 w-5 h-5 text-green-500"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                            <span className="leading-tight text-gray-500">
                              soundtrack composition
                            </span>
                          </li>
                          <li className="flex space-x-2.5">
                            <svg
                              className="flex-shrink-0 w-5 h-5 text-green-500"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                            <span className="leading-tight text-gray-500">
                              circuits and engineering design principles
                            </span>
                          </li>
                          <li className="flex space-x-2.5">
                            <svg
                              className="flex-shrink-0 w-5 h-5 text-green-500"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                            <span className="leading-tight text-gray-500">
                              All project-based learning
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="flex p-6 text-center bg-gray-50 lg:p-8">
                      <div className="self-center w-full">
                        <div className="text-5xl font-extrabold text-gray-900">
                          $1699
                        </div>
                        <div className="mt-1 mb-4 text-gray-500 text-light">
                          8 classes - 2 months
                        </div>
                        <a
                          href="/checkout"
                          className="flex justify-center text-white bg-dodger hover:bg-dodger focus:ring-4 focus:ring-bue-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                        >
                          Buy now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
}

export default Purchase;

// Ensure static generation
export async function getStaticProps() {
  return {
    props: {},
  };
}

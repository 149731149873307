import React from "react";
import BloominChef from "./PartnershipComponents/BloominChef";
import MIXXER from "./PartnershipComponents/MIXXER";
import Puberry from "./PartnershipComponents/Puberry";
import NewsletterCTA from "./UniversalComponents/NewsletterCTA";
import PartnershipsHero from "./PartnershipComponents/PartnershipsHero";

function Partners() {
    return (
        <div id='partners' className="pt-20">
            <PartnershipsHero />
            <BloominChef />
            <MIXXER />
            <Puberry />
            <NewsletterCTA />
        </div>
    );
}

export default Partners;
// Ensure static generation
export async function getStaticProps() {
  return {
    props: {},
  };
}

import React from "react";
import ScratchThumbnail from "../../assets/thumbnails/scratch-thumbnail.jpg";
import MinecraftThumbnail from "../../assets/thumbnails/minecraft-thumbnail.jpg";
import GIMPThumbnail from "../../assets/thumbnails/gimp-thumbnail.jpg";
import VideoThumbnail from "../../assets/thumbnails/video-thumbnail.png";
import MusicThumbnail from "../../assets/thumbnails/music-thumbnail.jpg";
import GameThumbnail from "../../assets/thumbnails/game-thumbnail.png";
import PythonThumbnail from "../../assets/thumbnails/python-thumbnail.png";
import JavaThumbnail from "../../assets/thumbnails/java-thumbnail.png";

const classData = require("./newDates.json");

class CheckoutStep2 extends React.Component {
  constructor(props) {
    super(props);

    const thumbnailMappings = {
      "Scratch Programming": ScratchThumbnail,
      "Minecraft Engineering": MinecraftThumbnail,
      "Python Programming": PythonThumbnail,
      "Java Programming": JavaThumbnail,
      "Graphic Design": GIMPThumbnail,
      "Video Editing": VideoThumbnail,
      "Music Production": MusicThumbnail,
      "Game Design": GameThumbnail,
    };

    Object.keys(classData.classes).forEach((className) => {
      classData.classes[className].image = thumbnailMappings[className];
    });

    this.state = {
      classes: classData.classes,
      selectedClass: null,
      date: "",
      time: "",
      registerNow: null,
      isValid: false,
    };
  }

  handleRegisterNow = () => {
    this.setState({ registerNow: true });
  };

  handlePurchaseLater = () => {
    this.setState({ registerNow: false });
  };

  handleClassChange = (className) => {
    this.setState({ selectedClass: className });
  };

  handlePurchaseLaterSubmit = (e) => {
    this.handleSubmit(e, true);
  };

  handleInputChange = (e) =>
    this.setState({ [e.target.name]: e.target.value }, this.updateFormValidity);

  handleRegisterChoice = (e, choice) => {
    e.preventDefault();
    this.setState({ registerNow: choice }, this.updateFormValidity);
  };

  updateFormValidity = () => {
    const { selectedClass, date, time, registerNow } = this.state;
    const isValid = registerNow ? Boolean(selectedClass && date && time) : true;
    this.setState({ isValid });
  };

  handleSubmit = async (e, purchaseLater = false) => {
    e.preventDefault();

    // update form validity
    this.updateFormValidity();

    console.log("Handling submit"); // Add this line

    // check if form is valid, otherwise send an alert
    if (!this.state.isValid) {
      alert("Please fill out all fields and try again");
      return;
    }

    let body = {};
    if (purchaseLater) {
      body = { order_id: localStorage.getItem("order_id"), isPurchased: true };
    } else {
      const { selectedClass, date, time } = this.state;
      body = {
        order_id: localStorage.getItem("order_id"),
        class_info: {
          title: selectedClass,
          time,
          startDate: date.split(" to ")[0],
          endDate: date.split(" to ")[1],
        },
      };
    } 

    const orderData = {
      order_id: localStorage.getItem("order_id"),
      class_info: {
        title: this.state.selectedClass,
        time: this.state.time,
        startDate: this.state.date.split(" to ")[0],
        endDate: this.state.date.split(" to ")[1],
      },
    };
    // set to local storage
    localStorage.setItem("orderData", JSON.stringify(orderData));
    try {
      const response = await fetch(
        "https://dev.bytesizelearning.org/step_two/",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
        }
      );
      console.log(response);
      const responseData = await response.json();
      if (!response.ok) throw new Error(responseData.message);

      this.props.handleData(this.state);
      this.props.nextStep();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  preemptiveOptionsRequest = () => {
    fetch("https://dev.bytesizelearning.org/step_two/", {
      method: "OPTIONS",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("OPTIONS request not successful");
        }
        return response.json();
      })
      .catch(error => {
        console.error("OPTIONS Error:", error);
      });
  };
  
  componentDidMount() {
    this.preemptiveOptionsRequest();
  } 

  renderButton = (condition, text, handler, extraClassName = "") => (
    <button
      type="button"
      className={`text-dodger font-bold py-2.5 px-5 rounded rounded-lg border-2 border-dodger p-2 ${
        condition ? "bg-dodger text-white" : "bg-white"
      } ${extraClassName}`}
      onClick={handler}
    >
      {text}
    </button>
  );

  render() {
    const { classes, selectedClass } = this.state;
    return (
      <section className="py-8 bg-white">
        <h2 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">
          Class Selection
        </h2>
        <div className="mb-4 mr-4">
          <button
            type="button"
            className={`${
              this.state.registerNow === true
                ? "bg-dodger text-white"
                : "bg-white"
            } text-dodger font-bold py-2.5 px-5 rounded rounded-lg border-2 border-dodger p-2 mr-4`}
            onClick={this.handleRegisterNow}
          >
            Register Now
          </button>
          <button
            type="button"
            className={`${
              this.state.registerNow === false
                ? "bg-dodger text-white"
                : "bg-white"
            } text-dodger font-bold py-2.5 px-5 rounded rounded-lg border-2 border-dodger p-2`}
            onClick={this.handlePurchaseLater}
          >
            Purchase a Ticket for Later
          </button>
        </div>
        {this.state.registerNow && (
          <form
            onSubmit={this.handleSubmit}
            className="grid gap-4 sm:gap-6 grid-cols-1"
          >
            <div className="grid grid-cols-2 gap-2 my-4">
              <label
                for="class"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white col-span-2"
              >
                Choose your class:
              </label>
              {Object.entries(this.state.classes).map(
                ([className, classInfo]) => (
                  <div
                    onClick={() => this.handleClassChange(className)}
                    className={`relative font-bold md:text-xl sm:text-lg bg-cover bg-center rounded-3xl cursor-pointer transition-colors duration-200 border-4 ${
                      this.state.selectedClass === className
                        ? "border-blue-500 text-dodger"
                        : "border-white text-white"
                    }`}
                    style={{
                      backgroundImage: `url(${classInfo.image})`,
                      height: "150px",
                    }}
                  >
                    <div className="absolute inset-0 flex items-center justify-center rounded-3xl border-4 border-transparent bg-black bg-opacity-50">
                      {className}
                    </div>
                  </div>
                )
              )}
            </div>
            {selectedClass && (
              <>
                <div className="mt-6 mb-2">
                  <h3 className="text-2xl font-bold mb-4 text-dodger">
                    {selectedClass}
                  </h3>
                  <p className="text-lg font-light">
                    {classes[selectedClass].description}
                  </p>
                </div>
              </>
            )}

            {this.state.registerNow && this.state.selectedClass !== null && (
              <>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
                  <h3 className="col-span-3 md:cols-span-3">Select a week</h3>
                  {this.state.classes[this.state.selectedClass].sessions.map(
                    (session, index) => (
                      <button
                        key={index}
                        type="button"
                        onClick={() =>
                          this.setState({
                            date:
                              session.start_date + " to " + session.end_date,
                          })
                        }
                        className={`px-5 py-2.5 sm:py-3.5 text-sm font-medium text-center rounded-lg border-2 border-dodger focus:ring-4 focus:outline-none focus:ring-primary-300 duration-200 ${
                          session.start_date + " to " + session.end_date ===
                          this.state.date
                            ? "text-white bg-dodger hover:bg-gray-200 hover:text-dodger"
                            : "text-dodger bg-white hover:bg-dodger hover:text-white"
                        }`}
                      >
                        {session.start_date + " to " + session.end_date}
                      </button>
                    )
                  )}
                </div>

                {this.state.date && (
                  <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
                    <h3 className="col-span-3 md:cols-span-3">Select a time</h3>
                    {this.state.classes[this.state.selectedClass].sessions
                      .filter(
                        (session) =>
                          session.start_date + " to " + session.end_date ===
                          this.state.date
                      )
                      .flatMap((session) => session.schedule)
                      .map((time) => (
                        <button
                          key={time}
                          type="button"
                          onClick={() => this.setState({ time: time })}
                          className={`px-5 py-2.5 sm:py-3.5 text-sm font-medium text-center rounded-lg border-2 border-dodger focus:ring-4 focus:outline-none focus:ring-primary-300 duration-200 ${
                            time === this.state.time
                              ? "text-white bg-dodger hover:bg-gray-200 hover:text-dodger"
                              : "text-dodger bg-white hover:bg-dodger hover:text-white"
                          }`}
                        >
                          {time}
                        </button>
                      ))}
                  </div>
                )}
                <button
                  type="submit"
                  className={`col-span-1 w-full mt-8 px-5 py-2.5 sm:py-3.5 text-sm bg-dodger font-medium text-center rounded-lg border-2 focus:outline-none duration-200 ${
                    !this.state.time
                      ? "text-gray-500 bg-gray-200 cursor-not-allowed"
                      : "text-white bg-dodger hover:bg-gray-200 hover:text-dodger focus:ring-4 focus:ring-primary-300"
                  }`}
                >
                  Next
                </button>
              </>
            )}
          </form>
        )}

        {this.state.registerNow &&
          this.state.selectedClass &&
          this.state.date &&
          this.state.time && (
            <p className="mt-4">
              You have selected <strong>{this.state.selectedClass}</strong> from{" "}
              <strong>{this.state.date}</strong> at{" "}
              <strong>{this.state.time}</strong>
            </p>
          )}
        {this.state.registerNow === false && (
          <button
            onClick={this.handlePurchaseLaterSubmit}
            className={`w-full mt-8 px-5 py-2.5 sm:py-3.5 text-sm bg-dodger font-medium text-center rounded-lg border-2 focus:outline-none duration-200 text-white bg-doder hover:bg-gray-200 hover:text-dodger focus:ring-4 focus:ring-primary-300`}
          >
            Purchase Ticket for Later
          </button>
        )}
      </section>
    );
  }
}

export default CheckoutStep2;

// Ensure static generation
export async function getStaticProps() {
  return {
    props: {},
  };
}

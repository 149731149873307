import React from "react";

function FAQs() {
    return (
        <div id="faqs">
            <section className="bg-white">
                <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6 ">
                    <h2 className="mb-6 lg:mb-8 text-3xl lg:text-4xl tracking-tight font-extrabold text-center text-gray-900">Frequently asked questions</h2>
                    <div className="mx-auto max-w-screen-md">
                        <div id="accordion-flush" data-accordion="collapse" data-active-classes="bg-white text-gray-900" data-inactive-classes="text-gray-500">
                            <h2 id="accordion-flush-heading-1">
                                <button type="button" className="flex justify-between items-center py-5 w-full font-medium text-left text-gray-900 bg-white border-b border-gray-200 " data-accordion-target="#accordion-flush-body-1" aria-expanded="true" aria-controls="accordion-flush-body-1">
                                    <span>What is ByteSize Learning?</span>
                                    <svg data-accordion-icon="" className="w-6 h-6 rotate-180 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </button>
                            </h2>
                            <div id="accordion-flush-body-1" className="" aria-labelledby="accordion-flush-heading-1">
                                <div className="py-5 border-b border-gray-200 ">
                                    <p className="mb-2 text-gray-500">ByteSize Learning offers young and curious students an opportunity to develop valuable skills in several software programs, and in programming languages. Our founders recognized the importance of teaching the next generation these skills so they will be prepared to build a better tomorrow. We saw our competitors fail to deliver high quality curriculum, to engage students in meaningful activities and to choose consistent, capable instructors.</p>
                                </div>
                            </div>

                            <h2 id="accordion-flush-heading-3">
                                <button type="button" className="flex justify-between items-center py-5 w-full font-medium text-left text-gray-500 border-b border-gray-200 " data-accordion-target="#accordion-flush-body-3" aria-expanded="false" aria-controls="accordion-flush-body-3">
                                    <span>What makes us different?</span>
                                    <svg data-accordion-icon="" className="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </button>
                            </h2>
                            <div id="accordion-flush-body-3" className="hidden" aria-labelledby="accordion-flush-heading-3">
                                <div className="py-5 border-b border-gray-200 ">
                                    <p className="mb-2 text-gray-500">We strive to keep all interactions and instruction personalized to ensure success for all of our students so we limit all of our offerings to 5 students per class. Each instructor also offers 1-on-1 sessions Tuesday-Friday if a student needs more personal attention.</p>

                                </div>
                            </div>
                            <h2 id="accordion-flush-heading-2">
                                <button type="button" className="flex justify-between items-center py-5 w-full font-medium text-left text-gray-500 border-b border-gray-200 " data-accordion-target="#accordion-flush-body-2" aria-expanded="false" aria-controls="accordion-flush-body-2">
                                    <span>How long are the classes?</span>
                                    <svg data-accordion-icon="" className="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </button>
                            </h2>
                            <div id="accordion-flush-body-2" className="hidden" aria-labelledby="accordion-flush-heading-2">
                                <div className="py-5 border-b border-gray-200 ">
                                    <p className="mb-2 text-gray-500">All ByteSize Learning courses are 1 week long and are composed of 5 hour long sessions. Our intermediate courses are a continuation of the beginner courses so students that are interested in further developing their skills can sign up for the beginner course and then the intermediate course for the following week.</p>
                                </div>
                            </div>
                            <h2 id="accordion-flush-heading-4">
                                <button type="button" className="flex justify-between items-center py-5 w-full font-medium text-left text-gray-500 border-b border-gray-200 " data-accordion-target="#accordion-flush-body-4" aria-expanded="false" aria-controls="accordion-flush-body-4">
                                    <span>What is the age range for your courses?</span>
                                    <svg data-accordion-icon="" className="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </button>
                            </h2>
                            <div id="accordion-flush-body-4" className="hidden" aria-labelledby="accordion-flush-heading-4">
                                <div className="py-5 border-b border-gray-200 ">
                                    <p className="mb-2 text-gray-500">Our courses are best suited for upper elementary school, middle school, and high school students. Inquire if you aren't sure about whether or not your child qualifies.</p>

                                </div>
                            </div>
                            <h2 id="accordion-flush-heading-5">
                                <button type="button" className="flex justify-between items-center py-5 w-full font-medium text-left text-gray-500 border-b border-gray-200 " data-accordion-target="#accordion-flush-body-5" aria-expanded="false" aria-controls="accordion-flush-body-5">
                                    <span>What equipment do I need to be successful?</span>
                                    <svg data-accordion-icon="" className="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </button>
                            </h2>
                            <div id="accordion-flush-body-5" className="hidden" aria-labelledby="accordion-flush-heading-5">
                                <div className="py-5 border-b border-gray-200 ">
                                    <p className="mb-2 text-gray-500">A computer (laptop or desktop) with access to high speed internet is required for all ByteSize Learning courses. For the smoothest user experience for GIMP and Unity classes, 8GB+ of RAM or an integrated graphics card is recommended although not required.</p>

                                </div>
                            </div>
                            <h2 id="accordion-flush-heading-6">
                                <button type="button" className="flex justify-between items-center py-5 w-full font-medium text-left text-gray-500 border-b border-gray-200 " data-accordion-target="#accordion-flush-body-6" aria-expanded="false" aria-controls="accordion-flush-body-6">
                                    <span>Can I make my own project?</span>
                                    <svg data-accordion-icon="" className="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </button>
                            </h2>
                            <div id="accordion-flush-body-6" className="hidden" aria-labelledby="accordion-flush-heading-6">
                                <div className="py-5 border-b border-gray-200 ">
                                    <p className="mb-2 text-gray-500">Absolutely! Each course is outfitted with between 2 and 5 sample projects that the students can work from but they are by no means limited to those projects. Instructors will do everything they can to ensure that a student is able to pursue a project of their choice.</p>
                                </div>
                            </div>
                            <h2 id="accordion-flush-heading-7">
                                <button type="button" className="flex justify-between items-center py-5 w-full font-medium text-left text-gray-500 border-b border-gray-200 " data-accordion-target="#accordion-flush-body-7" aria-expanded="false" aria-controls="accordion-flush-body-7">
                                    <span>Can I switch to another section or course after registering?</span>
                                    <svg data-accordion-icon="" className="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </button>
                            </h2>
                            <div id="accordion-flush-body-7" className="hidden" aria-labelledby="accordion-flush-heading-7">
                                <div className="py-5 border-b border-gray-200 ">
                                    <p className="mb-2 text-gray-500">We are willing to make any effort necessary to accommodate all students, however we cannot guarantee another sections availability if a student wishes to switch. For help with a scheduling issue, reach out to admin@bytesizelearning.org.</p>
                                </div>
                            </div>

                            <h2 id="accordion-flush-heading-8">
                                <button type="button" className="flex justify-between items-center py-5 w-full font-medium text-left text-gray-500 border-b border-gray-200 " data-accordion-target="#accordion-flush-body-8" aria-expanded="false" aria-controls="accordion-flush-body-8">
                                    <span>How are classes administered?</span>
                                    <svg data-accordion-icon="" className="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </button>
                            </h2>
                            <div id="accordion-flush-body-8" className="hidden" aria-labelledby="accordion-flush-heading-8">
                                <div className="py-5 border-b border-gray-200 ">
                                    <p className="mb-2 text-gray-500">Each course is taught in real-time, over video chat (Google Meet) with an experienced instructor. The instructor will be able to share their screen with the students and vice versa. Students are encouraged to ask questions and troubleshoot with their instructor. Courses are taught for 1-hour per day Monday-Friday with optional 1-on-1 sessions offered for students looking to review or seek additional help. </p>
                                </div>
                            </div>

                            <h2 id="accordion-flush-heading-9">
                                <button type="button" className="flex justify-between items-center py-5 w-full font-medium text-left text-gray-500 border-b border-gray-200 " data-accordion-target="#accordion-flush-body-9" aria-expanded="false" aria-controls="accordion-flush-body-9">
                                    <span>What class should I take?</span>
                                    <svg data-accordion-icon="" className="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </button>
                            </h2>
                            <div id="accordion-flush-body-9" className="hidden" aria-labelledby="accordion-flush-heading-9">
                                <div className="py-5 border-b border-gray-200 ">
                                    <p className="mb-2 text-gray-500"> If you are not sure which class to take, feel free to schedule a demo lesson with us and we can help you decide which class, if any, is the best fit for you! You can also always contact us through our website</p>
                                </div>
                            </div>

                            <h2 id="accordion-flush-heading-10">
                                <button type="button" className="flex justify-between items-center py-5 w-full font-medium text-left text-gray-500 border-b border-gray-200 " data-accordion-target="#accordion-flush-body-10" aria-expanded="false" aria-controls="accordion-flush-body-10">
                                    <span>When are classes offered?</span>
                                    <svg data-accordion-icon="" className="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </button>
                            </h2>
                            <div id="accordion-flush-body-10" className="hidden" aria-labelledby="accordion-flush-heading-10">
                                <div className="py-5 border-b border-gray-200 ">
                                    <p className="mb-2 text-gray-500"> Our classes mostly run in the summer months from the end of May to mid-August. We also offer some classes during the holiday breaks and throughout the year. Inquire if you'd like to take a class anytime of the year and we would be glad to work with you to make something work!</p>
                                </div>
                            </div>

                            <h2 id="accordion-flush-heading-11">
                                <button type="button" className="flex justify-between items-center py-5 w-full font-medium text-left text-gray-500 border-b border-gray-200 " data-accordion-target="#accordion-flush-body-11" aria-expanded="false" aria-controls="accordion-flush-body-11">
                                    <span>I already have experience in a course. Can I skip the introductory class?</span>
                                    <svg data-accordion-icon="" className="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </button>
                            </h2>
                            <div id="accordion-flush-body-11" className="hidden" aria-labelledby="accordion-flush-heading-11">
                                <div className="py-5 border-b border-gray-200 ">
                                    <p className="mb-2 text-gray-500"> Of course. All of our classes are designed to be for the introductory student, but we can accommodate students already with experience as well who wish to register for the intermediate class. Before doing so though, please request and review the introductory syllabus from admin[at]bytesizelearning.org to ensure you are prepared for the intermediate class.</p>
                                </div>
                            </div>
                            <h2 id="accordion-flush-heading-12">
                                <button type="button" className="flex justify-between items-center py-5 w-full font-medium text-left text-gray-500 border-b border-gray-200 " data-accordion-target="#accordion-flush-body-12" aria-expanded="false" aria-controls="accordion-flush-body-12">
                                    <span>Is there homework?</span>
                                    <svg data-accordion-icon="" className="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </button>
                            </h2>
                            <div id="accordion-flush-body-12" className="hidden" aria-labelledby="accordion-flush-heading-12">
                                <div className="py-5 border-b border-gray-200 ">
                                    <p className="mb-2 text-gray-500">After most classes, instructors will encourage the students to practice their new skills with a short activity to complete before the next class. This allows the student to explore the course's content and generate important questions. We have found the students learn more by practicing with support rather than just being told how to do something.  In a perfect world though, our students are so excited learning their program that this doesn’t even feel like a homework assignment!</p>
                                </div>
                            </div>
                            <h2 id="accordion-flush-heading-13">
                                <button type="button" className="flex justify-between items-center py-5 w-full font-medium text-left text-gray-500 border-b border-gray-200 " data-accordion-target="#accordion-flush-body-13" aria-expanded="false" aria-controls="accordion-flush-body-13">
                                    <span>How do I install the software necessary for a class?</span>
                                    <svg data-accordion-icon="" className="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </button>
                            </h2>
                            <div id="accordion-flush-body-13" className="hidden" aria-labelledby="accordion-flush-heading-13">
                                <div className="py-5 border-b border-gray-200 ">
                                    <p className="mb-2 text-gray-500"> Upon registration for a class, the parent and/or student will receive a comprehensive instruction guide on installing the software prior to the first class. If any problems arise, contact admin[at]bytesizelearning.org.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default FAQs;
// Ensure static generation
export async function getStaticProps() {
  return {
    props: {},
  };
}
